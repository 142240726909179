<template>
    <ion-list v-if="isDesktop" class="search-elements">
        <ion-item lines="none" color="none" class="ion-no-padding">
            <ion-searchbar :placeholder="$t('Search')" mode="ios" :debounce="300" @ionInput="roadbooksStore.searchRoadbook($event.detail.value)" @ionClear="" ></ion-searchbar>
            <ion-button :color="roadbooksStore.filtersCount ? 'primary':'petrol'" @click="showFilters=!showFilters" expand="block" class="filter-button">
                <MaterialSymbol name="tune" size="1.3rem"></MaterialSymbol>
                <ion-text>{{$t('Filters')}}</ion-text>
            </ion-button>
        </ion-item>
        <ion-item-divider></ion-item-divider>
        <RoadbooksFilters v-if="showFilters"></RoadbooksFilters>
    </ion-list>
    <ion-item v-if="isMobile" class="ion-margin-start ion-margin-top" color="none" lines="none">
        <ion-text color="primary">{{ $t('Categories') }}</ion-text>
    </ion-item>
        
    <ion-list>
        <ion-item button @click="roadbooksStore.updateDisplayed('allRoadbooks')" lines="none" color="petrol">
            <MaterialSymbol color="primary" class="ion-margin-end" name="list"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == 'allRoadbooks' ? 'primary':'light'">{{$t('All roadbooks')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.roadbooks.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-item button @click="roadbooksStore.updateDisplayed(LASTACTIVITIES_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" class="ion-margin-end" name="history"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == LASTACTIVITIES_CATEGORY ? 'primary':'light'">{{$t('Last activities')}}</ion-label>
        </ion-item>
        <ion-item v-if="devicesStore.verifiedDevices.length" button @click="roadbooksStore.updateDisplayed(UPLOADHISTORY_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" class="ion-margin-end" name="smart_screen"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == UPLOADHISTORY_CATEGORY ? 'primary':'light'">{{$t('Upload history')}}</ion-label>
        </ion-item>
        <ion-item button @click="roadbooksStore.updateDisplayed(FAVORITES_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" class="ion-margin-end" name="favorite"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == FAVORITES_CATEGORY ? 'primary':'light'">{{$t('Favorite')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.favorites.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-item button @click="roadbooksStore.updateDisplayed(FLASH_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" name="flash_on" class="ion-margin-end"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == FLASH_CATEGORY ? 'primary':'light'">{{$t('Flash')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.flash.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-item button @click="roadbooksStore.updateDisplayed('discovery')" lines="none" color="petrol">
            <MaterialSymbol color="primary" name="360"  class="ion-margin-end"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == 'discovery' ? 'primary':'light'">{{$t('Boomerang')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.discovery.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-item button  @click="roadbooksStore.updateDisplayed(ROUTING_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" name="edit_road" class="ion-margin-end"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == ROUTING_CATEGORY ? 'primary':'light'">{{$t('Expert')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.routing.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-item button  @click="roadbooksStore.updateDisplayed(ARCHIVED_CATEGORY)" lines="none" color="petrol">
            <MaterialSymbol color="primary" name="inventory_2"  class="ion-margin-end"></MaterialSymbol>
            <ion-label :color="roadbooksStore.selectedCategory == ARCHIVED_CATEGORY ? 'primary':'light'">{{$t('Archived')}}</ion-label>
            <ion-chip color="primary" outline="true">
                <ion-label>{{roadbooksStore.archived.length}}</ion-label>
            </ion-chip>
        </ion-item>
        <ion-button v-if="isDesktop" fill="outline" color="primary" expand="block" class="import-buttion">
            <MaterialSymbol color="primary" name="download"  class="ion-margin-end"></MaterialSymbol>
            <ion-label color="light">{{$t('Import')}}</ion-label>
        </ion-button>
    </ion-list>

    <ion-list>
        <ion-item-divider></ion-item-divider>
    </ion-list>
        
    <ion-list>
        <ion-item color="none" lines="none">
            <ion-text color="primary">{{ $t('Albums') }}</ion-text>
            <CreateAlbumButton></CreateAlbumButton>
        </ion-item>
        <ion-item v-for="album of roadbooksStore.albums" button lines="none" color="anthracite" @mouseover="albumOver = album.id" @mouseleave="albumOver = ''" @click="roadbooksStore.updateDisplayed(album)">
            <MaterialSymbol color="primary" name="bookmark"  class="ion-margin-end"></MaterialSymbol>
            <ion-label>{{ album.label }}</ion-label>
            <ion-buttons v-if="albumOver == album.id">
                <ion-button :id="`album-menu-${album.label}`">
                    <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
                </ion-button>
                <ion-popover :trigger="`album-menu-${album.label}`" :dismiss-on-select="true">
                    <ion-content>
                        <ion-item button color="anthracite" @click="updateAlbumAlert(album)">
                            <ion-icon slot="start" :icon="create"></ion-icon>
                            <ion-label>{{$t('Rename')}}</ion-label>
                        </ion-item>
                        <ion-item button color="anthracite" @click="deleteAlbumAlert(album)">
                            <ion-icon slot="start" :icon="trash" color="danger"></ion-icon>
                            <ion-label color="danger">{{$t('Remove')}}</ion-label>
                        </ion-item>
                    </ion-content>
                </ion-popover>
            </ion-buttons>
            <ion-chip v-else color="primary" mode="ios" outline="true">
                <ion-label>{{ album.roadbook_ids.length }}</ion-label>
            </ion-chip>

        </ion-item>
    </ion-list>

</template>

<script setup lang="ts">
    import { computed, inject, onMounted, ref } from 'vue';
    import { ARCHIVED_CATEGORY, FAVORITES_CATEGORY, FLASH_CATEGORY, LASTACTIVITIES_CATEGORY, RoadbooksStore, ROUTING_CATEGORY, UPLOADHISTORY_CATEGORY } from '@/stores/RoadbooksStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { create, ellipsisVertical, trash } from 'ionicons/icons';
    import { useI18n } from 'vue-i18n';
    import { albumAPI, roadbookAPI } from '@/libs/backend';
    import { alertController, loadingController } from '@ionic/vue';
    import CreateAlbumButton from './CreateAlbumButton.vue';
    import RoadbooksFilters from './RoadbooksFilters.vue';
    import { actionLoader } from '@/libs/userExperience';
    import { DevicesStore } from '@/stores/DevicesStore';

    // Store
    const roadbooksStore = RoadbooksStore();
    const devicesStore = DevicesStore()

    // Vue
    const i18n = useI18n();

    // Variables
    const isDesktop = inject('isDesktop');
    const isMobile = inject('isMobile');
    const showFilters = ref(false)
    const albumOver = ref('');

    onMounted(()=>{
        if(!devicesStore.initialized) devicesStore.init()
    })

    async function deleteAlbumAlert(album:any){
        const alert = await alertController.create({
            header:i18n.t('Delete album'),
            buttons:[{role: 'cancel', text: i18n.t('Cancel')}, {role:'confirm', text:i18n.t('OK'), handler:(e)=>{console.log(e), deleteAlbum(album, e[0])}}],
            inputs:[
                {
                    type: 'checkbox',
                    label: i18n.t("Delete album's trips"),
                    value: true
                }
            ]
        });
        await alert.present();
    }
    async function updateAlbumAlert(album:any){
        const alert = await alertController.create({
            header:i18n.t('Update album'),
            buttons:[{role: 'cancel', text: i18n.t('Cancel')}, {role:'confirm', text:i18n.t('OK'), handler:(e)=>{updateAlbum(album, e[0])}}],
            inputs:[
                {
                    placeholder: i18n.t('Album name'),
                    value: album.label
                }
            ]
        });
        await alert.present();
    }
    function updateAlbum(album:any, label:string){
        albumAPI.update(album.id, {label:label}).then(async(updatedAlbum)=>{
            roadbooksStore.refreshAlbumData(updatedAlbum)
        })
    }
    async function deleteAlbum(album:any, deleteRoadbooks=undefined){
        let loader = await actionLoader()
        if(deleteRoadbooks == true){
            for(let roadbookId of album.roadbook_ids){
                roadbookAPI.delete(roadbookId).then(()=>{
                })
            }
        }
        albumAPI.delete(album.id).then(()=>{
            if(deleteRoadbooks == true){
                roadbooksStore.fetch()
            }
            else{
                roadbooksStore.fetchAlbums()
            }
            roadbooksStore.selectedCategory = 'allRoadbooks'
        })

        loader.dismiss()
    }
</script>

<style scoped>

    ion-list {
        padding: 0;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        background-color: var(--ion-color-anthracite);
        margin-bottom: 20px;
    }

    .search-elements ion-item{
        --inner-padding-end: 0 !important;
    }
    .search-elements ion-searchbar {
        padding-left: 0px;
        margin-left: 0px !important;
        padding-right: 0;
        margin-left: 10px;
        --background: var(--ion-color-petrol);
        --color: #fff;
        --placeholder-color: #fff;
        --icon-color: #fff;
        --clear-button-color: #fff;
        --border-radius: 10px;
    }

    .search-elements ion-button.filter-button {
        --border-radius: 10px;
        height: 50px;
        margin-left: 10px;
        margin-right: 0px !important;
        padding-right: 0px !important;
    }
    .search-elements ion-button.filter-button ion-text{
        margin-left: 5px;
    }
    
    ion-item.ion-activatable {
        border-radius: 10px;
        margin-bottom: 8px;
    }
    .import-buttion {
        border-radius: 10px;
    }

    ion-item-divider {
        opacity: 0.5;
    }
</style>
