<template>
    <ion-buttons v-if="isDesktop" :slot="btnSlot" :class="btnClass">
        <ion-button id="filter-popover" shape="rounded" size="medium">
            <!-- <ion-icon slot="start" :icon="funnel"></ion-icon> -->
            <MaterialSymbol name="filter_list" size="2rem" class="ion-margin-end"></MaterialSymbol>
            {{$t('Sort by')}}
        </ion-button>
    </ion-buttons>
    <ion-popover v-if="isDesktop" trigger="filter-popover" :dismiss-on-select="true">
        <ion-content>
            <ion-list>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('title')">
                    <ion-icon color="medium" :icon="textOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Name A-Z')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('title', 'desc')">
                    <ion-icon color="medium" :icon="textOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Name Z-A')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('updated_date', 'desc')">
                    <ion-icon color="medium" :icon="timeOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Newest')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('updated_date')">
                    <ion-icon color="medium" :icon="timeOutline"></ion-icon><ion-label class="ion-margin">{{ $t('Oldest')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('total_distance', 'desc')">
                    <MaterialSymbol name="subdirectory_arrow_right" color="medium"></MaterialSymbol><ion-label class="ion-margin">{{ $t('Greatest distance')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('total_distance')">
                    <MaterialSymbol name="subdirectory_arrow_right" color="medium"></MaterialSymbol><ion-label class="ion-margin">{{ $t('Smallest distance')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('estimated_time', 'desc')">
                    <MaterialSymbol name="timer" color="medium"></MaterialSymbol><ion-label class="ion-margin">{{ $t('Greatest estimated time')}}</ion-label>
                </ion-item>
                <ion-item lines="none" button @click="roadbooksStore.orderBy('estimated_time')">
                    <MaterialSymbol name="timer" color="medium"></MaterialSymbol><ion-label class="ion-margin">{{ $t('Smallest estimated time')}}</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-popover>

    <ion-select v-if="isMobile" mode="md" value="title,asc" @ionChange="mobileSelectSort">
        <ion-select-option value="title,asc">{{ $t('Name A-Z')}}</ion-select-option>
        <ion-select-option value="title,desc">{{ $t('Name Z-A')}}</ion-select-option>
        <ion-select-option value="updated_date,asc">{{ $t('Newest')}}</ion-select-option>
        <ion-select-option value="updated_date,desc">{{ $t('Oldest')}}</ion-select-option>
        <ion-select-option value="total_distance,asc">{{ $t('Greatest distance')}}</ion-select-option>
        <ion-select-option value="total_distance,desc">{{ $t('Smallest distance')}}</ion-select-option>
        <ion-select-option value="estimated_time,asc">{{ $t('Greatest estimated time')}}</ion-select-option>
        <ion-select-option value="estimated_time,desc">{{ $t('Smallest estimated time')}}</ion-select-option>
    </ion-select>
</template>

<script setup lang="ts">
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { textOutline, timeOutline } from 'ionicons/icons';
    import { inject, onMounted, watch } from 'vue';

    const props = defineProps({
        btnClass: {
            type: String,
            required: false
        },
        btnSlot: {
            type: String,
            required: false
        }
    });

    const emit = defineEmits(['sorted'])

    const isDesktop = inject('isDesktop')
    const isMobile = inject('isMobile')
    const roadbooksStore = RoadbooksStore()

    function mobileSelectSort(event:any){
        const [field, order] = event.detail.value.split(',');
        roadbooksStore.orderBy(field, order)
        emit('sorted');
    }

</script>

<style scoped>
</style>