<template>
    <div :class="isDesktop? 'desktop-gallery':''">      
        <div :class="`roadbook-cards ${isDesktop?'desktop-cards':'mobile-cards'}`">
            <div v-if="roadbooksStore.displayedRoadbooks.length">
                <RoadbookCard v-for="roadbook of roadbooksStore.displayedRoadbooks" :roadbook="roadbook" :selectionMode="roadbooksStore.selectionEnabled" :selected="roadbooksStore.isSelected(roadbook)"
                    @select="roadbooksStore.selectionEnabled ? roadbooksStore.select(roadbook):editRoadook(roadbook)"></RoadbookCard>
            </div>
            <div v-show="!roadbooksStore.displayedRoadbooks.length" class="div-center ion-text-center no-result">
                <ion-row>
                    <ion-col size="12">
                        <ion-text class="ion-text-uppercase"><h1>{{ $t('No roadbook') }}</h1></ion-text>
                    </ion-col>
                    <ion-col size="12">
                        <img src="/road-1.png"></img>
                    </ion-col>
                    <ion-col size="12">
                        <ion-button v-if="!roadbooksStore.roadbooks.length" @click="roadbookModal.open()" shape="round" size="large" class="bold">
                            {{ $t("Create a trip")}}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </div>
        </div>
    </div>


    <RoadbookInfoModal ref="roadbookInfoModal"></RoadbookInfoModal>
    <TripModal ref="roadbookModal"></TripModal>
    <TripyUploadModal ref="tripyUpload" :saveGPX="false"></TripyUploadModal>
</template>

<script setup lang="ts">
    import RoadbookInfoModal from './RoadbookInfoModal.vue';
    import TripyUploadModal from './TripyUploadModal.vue';
    import TripModal from './TripModal.vue';
    import RoadbookCard from './RoadbookCard.vue';
    import { ref, inject, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';

    // Stores
    const roadbooksStore = RoadbooksStore();

    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')

    // Vue
    const router = useRouter();
    
    // HTML Element refs
    const roadbookModal = ref()
    const roadbookInfoModal = ref()
    const lottieNoResult = ref()
    const tripyUpload = ref()

    function editRoadook(roadbook:any){
        if(roadbooksStore.selectionEnabled == false){
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }
</script>

<style scoped>
    ion-card {
        border-radius: 10px;
    }
    .selectable{
        border: 1px solid white;
    }
    .selected{
        border: 1px solid var(--ion-color-tertiary) !important;
    }
    .favortie {
        z-index: 200;
        position: absolute;
        font-size: 50px;
        background-color: var(--ion-component-background);
        bottom: 0px;
        right: 0px;
        border-radius: 6px;
    }
    .actions-overlay {
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(150,150,150,0.5);
    }
    .actions-overlay ion-button {
        height: 60px;
        font-size: 20px
    }
    .actions-overlay ion-button:first-child {
        margin-top: 35%;
    }

    ion-segment.icon-segment {
        width: 150px;
    }

    .no-result {
        margin-top: -100px;
    }
    .no-result img {
        height: 200px; width: 200px;
    }

    .multi-select-actions-modal {
        --height: 400px;
    }

    .desktop-gallery {
        margin-left: 30px;
        margin-right: 30px;
    }

    .roadbook-cards {
        height: 100%;
        position: fixed;
        left: 0;
        margin-top: 20px;
        height: 100%;
        width: 100%;
        padding-bottom: 150px;
        overflow-y: scroll;
        /* border-top: 2px solid var(--ion-color-step-550); */
    }
    .desktop-cards {
        padding-left: 30px;
        padding-right: 30px;
    }
    .mobile-cards {
        padding-left: 5px;
        padding-right: 5px;
    }

    ion-toolbar ion-title:hover {
        cursor: pointer;
    }
</style>