<template>
    <l-layer-group v-if="startWaypoint">
        <!-- Start -->
        <l-marker v-if="!roadbookStore.isRoundTrip()" :lat-lng="startWaypoint" :draggable="interactive? true:false"
            @moveend="moveWaypoint(0, $event)" @contextmenu="$event.target.openPopup()" @mouseover="emit('mouseover')" @mouseout="emit('mouseleave')">
            <l-icon :iconSize="[46, 60]" :iconAnchor="[30, 45]" :popupAnchor="[0,-40]" :options="{className:''}">
                <img src="/start.svg" style="height:46px;width:60px;"/>
            </l-icon>
            <MapWaypointsPopover v-if="interactive" :profileSelect="profileSelect" :latLon="roadbookStore.value.map_waypoints[0]" isStart :stepNumber="1"></MapWaypointsPopover>
        </l-marker>
        
        <!-- Steps -->
        <l-marker v-if="stepWaypoints?.length" v-for="latlng, i of stepWaypoints" :lat-lng="latlng" :draggable="interactive? true:false"
            @moveend="moveWaypoint(i+1, $event)" @contextmenu="$event.target.openPopup()" @mouseover="emit('mouseover')" @mouseout="emit('mouseleave')">
            <l-icon :iconSize="[32, 32]" :iconAnchor="[18, 32]" :popupAnchor="[1,-30]" :options="{className:''}"> 
                <PinIcon :number="roadbookStore.options.marker_number? i+1:0"></PinIcon>
            </l-icon>
            <MapWaypointsPopover v-if="interactive" :profileSelect="profileSelect" :latLon="roadbookStore.value.map_waypoints[i+1]" isStep :stepNumber="i+1"></MapWaypointsPopover>
        </l-marker> 

        <!-- Stop -->
        <l-marker v-if="!roadbookStore.isRoundTrip() && stopWaypoint" :lat-lng="stopWaypoint" :draggable="interactive? true:false" 
            @moveend="moveWaypoint(roadbookStore.value.waypoints.length-1, $event)" @contextmenu="$event.target.openPopup()" @mouseover="emit('mouseover')" @mouseout="emit('mouseleave')">
            <l-icon icon-url="/stop.svg" :iconSize="[46, 60]" :iconAnchor="[30, 45]" :popupAnchor="[0,-40]" :options="{className:''}">
                <img src="/stop.svg" style="height:46px;width:60px;"/>
            </l-icon>
            <MapWaypointsPopover v-if="interactive" :profileSelect="profileSelect" :latLon="roadbookStore.value.map_waypoints[roadbookStore.value.map_waypoints.length-1]"  isDestination :stepNumber="roadbookStore.value.map_waypoints.length"></MapWaypointsPopover>
        </l-marker>

        <!-- Start/Stop (roundtrip) -->
        <l-marker v-if="roadbookStore.isRoundTrip() && startWaypoint && stopWaypoint" :lat-lng="startWaypoint" :draggable="interactive? true:false"
            @moveend="moveWaypoint(0, $event)" @contextmenu="$event.target.openPopup()" @mouseover="emit('mouseover')" @mouseout="emit('mouseleave')">
            <l-icon :iconSize="[46, 60]" :iconAnchor="[30, 45]" :popupAnchor="[0,-40]" :options="{className:''}">
                <img src="/start-stop.svg" style="height:46px;width:60px;"/>
            </l-icon>
            <MapWaypointsPopover v-if="interactive" :profileSelect="profileSelect" :latLon="roadbookStore.value.map_waypoints[0]" isStart :stepNumber="1"></MapWaypointsPopover>
        </l-marker> 
    </l-layer-group>
</template>

<script setup lang="ts">
    import { LMarker, LLayerGroup, LIcon } from "@vue-leaflet/vue-leaflet";
    import MapWaypointsPopover from "./MapWaypointsPopover.vue";
    import PinIcon from "@/components/icons/PinIcon.vue";
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { computed, toRaw } from "vue";

    const roadbookStore = roadbookEditStore()

    defineProps({
        interactive: {
            type: Boolean,
            default: true,
        },
        profileSelect: {
            type: Boolean,
            default: true,
        }
    });

    const emit = defineEmits([
        'moved',
        'mouseover',
        'mouseleave',
    ]);

    const startWaypoint = computed({
        get() {
            if(roadbookStore.value.map_waypoints.length && !roadbookStore.value.waypoints.length)
                return toRaw(roadbookStore.value.map_waypoints[0]) // first point is not routed when clicked
            if(roadbookStore.value.waypoints.length)
                return toRaw(roadbookStore.value.waypoints[0])
            else
                return undefined
        },
        set(value) {  }
    });

    const stopWaypoint = computed({
        get() {
            if(roadbookStore.value.waypoints.length >= 2)
                return toRaw(roadbookStore.value.waypoints[roadbookStore.value.waypoints.length-1])
            else
                return undefined
        },
        set(value) {  }
    });

    const stepWaypoints = computed({
        get() {
            if(roadbookStore.value.waypoints.length > 2){
                return toRaw(roadbookStore.value.waypoints).slice(1,-1)
            }
            else
                return undefined
        },
        set(value) {  }
    });

    function moveWaypoint(wpIndex:number, e:any){
        let newLatLng = e.target.getLatLng()
        roadbookStore.moveWaypoint(wpIndex, [newLatLng.lat, newLatLng.lng])
        emit('moved', wpIndex, e)
    }


</script>

<style scoped></style>









