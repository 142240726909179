<template>
        <ion-item lines="none" color="anthracite" class="ion-no-padding">
            <ion-label>{{$t('Distance')}}
            <p>
                <ion-range color="primary" class="distance-selector"
                    min="0" :max="roadbooksStore.maxDistance" step="20"
                    dualKnobs="true" pin="true"
                    :pinFormatter="(value:number) => `${Math.round(value)}${$t('km')}`"
                    snaps="true"
                    ticks="true" 
                    v-model="roadbooksStore.rangeValue"
                    @ionChange="roadbooksStore.updateDisplayed()">
                </ion-range>
            </p>
            </ion-label>
        </ion-item>
        <ion-button v-if="roadbooksStore.filtersCount" @click="roadbooksStore.resetFilters" fill="outline" expand="block" color="light" class="ion-text-center ion-margin-bottom" size="medim">
            <ion-icon slot="start" :icon="close"></ion-icon>
            {{$t('Reset filters')}}
        </ion-button>
        <ion-item-divider></ion-item-divider>

</template>

<script setup lang="ts">
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { close } from 'ionicons/icons';

    // Store
    const roadbooksStore = RoadbooksStore();

</script>