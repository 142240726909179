<template>
    <div ref="mapContainer" :style="'height:'+height+'px; width:100%'" @keyup.space="addWaypointFromCursorPosition">
        
        <!-- overlay during map loading -->
        <PageLoader :loading="!isReady()" ></PageLoader>

        <l-map ref="map" v-model:zoom="mapZoom" v-model:center="mapCenter" 
            :useGlobalLeaflet="parameters.useGlobalLeaflet" :options="parameters.options"
            @ready="initMap"
            @mousedown="checkLongClick"
            @click="addWaypoint($event.latlng)"
            @keyup.space="addWaypointFromCursorPosition"
            @mousemove="updateCursorPosition"
            @contextmenu="openContextMenu"
            @update:center="updateMapCenter"
        >
    
            <l-tile-layer :url="roadbookStore.map_layer" layer-type="base" name="TripyMap3"></l-tile-layer>
            
            <MapWaypointsMarkers :interactive="interactive" :profileSelect="waypointProfileSelection" @mouseover="displayTracePicker=false" @mouseleave="displayTracePicker=true"></MapWaypointsMarkers>

            <l-marker v-if="contextMenuLatLng.length" ref="contextMenu" :lat-lng="contextMenuLatLng">
                <MapWaypointsPopover v-if="interactive" :latLon="contextMenuLatLng" ref="contextMenuPopup" isContextMenu></MapWaypointsPopover>
            </l-marker>

            <l-layer-group v-if="showUserLocation == true">
                <l-circle-marker v-if="interactive && geoLocation.location" :lat-lng="geoLocation.location" :weight="6" :fillOpacity="1" :radius="10" color="#222428" fill-color="#ffd50f">
                    <MapWaypointsPopover :latLon="geoLocation.location" ref="infoPopup" isCurrentLocation></MapWaypointsPopover>
                </l-circle-marker>
            </l-layer-group>
            <l-control-zoom v-if="roadbookStore.options.map_zoom_buttons" position="topright"  ></l-control-zoom>

            <MapTrace v-if="points.length" :points="points" :pointsSections="pointsSections" :displayPicker="displayPicker" :cursorPosition="cursorPosition" :zoomLevel="map.leafletObject.getZoom()"></MapTrace>

            <!-- SEARCH RESULT LAYER -->
            <l-layer-group id="information-layer" v-if="interactive">
                <l-marker v-if="mapStore.focusedPlace" :lat-lng="mapStore.focusedLatLon()"> 
                    <l-icon :iconSize="[32, 32]" :iconAnchor="[16, 32]" :popupAnchor="[3,-25]" :options="{className:''}"> 
                        <PinIcon color="#3880ff"></PinIcon>
                    </l-icon>
                    <MapWaypointsPopover :latLon="mapStore.focusedLatLon()" isPlace :autoOpen="true"></MapWaypointsPopover>
                </l-marker>
            </l-layer-group>
        </l-map>

    </div>
</template>

<script setup lang="ts">
   
    /* Leaflet */
    import "leaflet"; // to use L object
    import "leaflet/dist/leaflet.css";
    import { LMap, LTileLayer, LMarker, LCircleMarker, LControlZoom, LLayerGroup, LIcon } from "@vue-leaflet/vue-leaflet";
    import { computed, inject, ref, watch } from 'vue';
    import { waitElVisible } from '@/libs/userExperience';
    import MapWaypointsMarkers from "./MapWaypointsMarkers.vue";
    import MapWaypointsPopover from "./MapWaypointsPopover.vue";
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import { geoLocationStore } from "@/stores/geoLocationStore";
    import PinIcon from "./icons/PinIcon.vue";
    import MapTrace from "./MapTrace.vue";
    import PageLoader from "./PageLoader.vue";
    import { MapStore } from "@/stores/mapStore";

    // HTML components refs
    const contextMenuPopup = ref();
    const infoPopup = ref();
    const map = ref();
    const contextMenu = ref();
    const mapContainer = ref();

    // Stores
    const roadbookStore = roadbookEditStore();
    const mapStore = MapStore();
    const geoLocation = geoLocationStore();

    // Variable refs
    var mapInitialized = false
    var userLocationFocused = false;
    const ready = ref(false);
    const cursorPosition:any = ref(null);
    const displayTracePicker = ref(true);
    const displayPicker = computed( () => props.tracePicker && displayTracePicker.value )
    const contextMenuLatLng:any = ref([]);
    const zoom:any = ref(null);
    const center:any = ref(null);
    const isDesktop = inject('isDesktop')
    const mapZoom = computed({
        get() {
            if(zoom.value) return zoom.value
            else return props.parameters.zoom
        },
        set(value) { zoom.value = value }
    })
    const mapCenter = computed({
        get() {
            if(center.value) return center.value
            else return props.parameters.center
        },
        set(value) { center.value = value }
    })

    const props = defineProps({
        parameters: {
            type: Object,
            default: {
                // TODO: local position if detected, Home country if not, else Europe center
                center: [46.66181748307193, 7.799474973236367], // centered on Europe by default
                zoom: 3,
                useGlobalLeaflet: false,
                options: {
                    zoomControl: false, 
                    zoomSnap:0.1, // Zoom precision ratio, default 1. Usefull to use fitBounds with padding precision
                    attributionControl: false // disable leaflet map attribution label
                },
            }
        },
        height: {
            type: Number,
            default: window.innerHeight - 75 // 75px is the default header height in style.css 
        },
        showLoader: {
            type: Boolean,
            required: false,
            default: true
        },
        showUserLocation: {
            type: Boolean,
            required: false,
            default: true
        },
        interactive: {
            type: Boolean,
            required: false,
            default: true
        },
        points: {
            type: Array<Array<Number>>,
            required: false,
            default: [],
        },
        pointsSections: {
            type: Array<Array<Number|String>>,
            required: false,
            default: [],
        },
        waypoints: {
            type: Array<Array<number|string>>,
            required: false,
            default: []
        },
        waypointProfileSelection: { // show or hide profile selection on waypoints Markers
            type: Boolean,
            required: false,
            default: true
        },
        tracePicker: {
            type: Boolean,
            required: false,
            default: true
        },
        autoFocusUserLocation: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    defineExpose({
         showPlace,
         showCurrentLocation,
         isReady,
         fitPoints
    });

    const emit = defineEmits([
        'onWaypointsChanged',
        'fitted',
        'moved'
    ]); 

    
    watch(() => props.points, () => {
        if(roadbookStore.options.automatic_feet) fitPoints();
    });

    if(props.showUserLocation && props.autoFocusUserLocation){

        watch(() => geoLocation.location, () => {
            focusUserLocation();
        });
    
        watch(() => roadbookStore.initialized, () => {
            focusUserLocation();
        });
    }

    function focusUserLocation(){
        if(!userLocationFocused && geoLocation.location && roadbookStore.initialized && !roadbookStore.value.points.length) {
            setTimeout(()=>{ // let time to map initialization
                showCurrentLocation();
                userLocationFocused = true;
            },200)
        }
    }
    
    function initMap(){
        // wait data available to finalize INIT
        if(!roadbookStore.isNew() && (!roadbookStore.initialized || ! map.value.leafletObject)) {
            setTimeout(function () {
                initMap()
            }, 100);
            return;
        }

        map.value.leafletObject.invalidateSize(true);
        fitPoints();
        updateMapCenter(map.value.leafletObject.getCenter() );
        ready.value = true

        mapContainer.value.focus(); // TODO ! focus map container to have keyboard shortcuts available on page load

        mapStore.init(map.value.leafletObject);

        if(props.autoFocusUserLocation) {
            focusUserLocation();
        }
    }

    function isReady(){
        return ready.value
    }
    
    function fitPoints(){
        // only works after map and points are ready
        if(!props.points || !props.points.length || ! map.value.leafletObject) return
        
        mapInitialized = true;

        let fitBounds:any = []
        if(roadbookStore.value.bbox){ // if router result has returned a bbox
            fitBounds.push({lat:roadbookStore.value.bbox[0][0], lng:roadbookStore.value.bbox[0][1]})
            fitBounds.push({lat:roadbookStore.value.bbox[1][0], lng:roadbookStore.value.bbox[1][1]})
        }
        else { // calculate ourself (could be long time estimation due to number of points)
            props.points.forEach((latlng:any) => {
                let b = latlng.reduce(function(result:any, item:any, index:number, array:any[]) {
                    result['lat'] = array[0];
                    result['lng'] = array[1];
                    return result;
                }, {})
                fitBounds.push(b)
            });
        }

        if(mapStore.focusedLatLon()){
            fitBounds.push(mapStore.focusedLatLon())
        }

        map.value.leafletObject.fitBounds(fitBounds, {paddingTopLeft: [isDesktop? 80:40, isDesktop? 80:120], paddingBottomRight: [isDesktop? 80:40, isDesktop? 80:300]}) // Add second param {maxZoom: 10} to set zoom
        if(props.interactive == false){
            roadbookStore.value.waypoints = props.waypoints; // for readOnly map
        }
        
        emit('fitted')
    }

    // Fix @ready event not emited of page reresh
    setTimeout(() => { 
        if(! mapInitialized) fitPoints();
    }, 300);

    var now = new Date();
    function checkLongClick(){
        now = new Date();
    }

    function addWaypoint(latlng:any){
        if(!props.interactive) return
        if((new Date().getTime())-now.getTime() > 500) return // 500ms hold clicking
        if(roadbookStore.popoverOpened){
            closePopups()
        }
        else{
            roadbookStore.addWaypoint([latlng.lat, latlng.lng]);
        }
    }

    function addWaypointFromCursorPosition(){
        if(!props.interactive) return
        addWaypoint(cursorPosition.value)
    }

    function updateCursorPosition(e:any){  
        if(!props.interactive) return
        cursorPosition.value = e.latlng
    }

    function openContextMenu(e:any){
        if(!props.interactive) return
        contextMenuLatLng.value = [e.latlng.lat, e.latlng.lng]
        waitElVisible(()=>{
            contextMenu.value.leafletObject.openPopup();
        })
    }

    function updateMapCenter(latlnt:any){
        roadbookStore.value.map_center = [latlnt.lat, latlnt.lng]
        emit('moved')
    }
   
    function closePopups(){
        if(map.value.leafletObject)
            map.value.leafletObject.closePopup();
    }

    function focusLatLon(latlng:any){
        closePopups();
        map.value.leafletObject.setView(latlng, 14)
    }

    function showPlace(place:any){
        focusLatLon([parseFloat(place.lat), parseFloat(place.lon)])
    }

    function showCurrentLocation(){
        if(geoLocation.location) focusLatLon(geoLocation.location)
    }

</script>

<style scoped>
.leaflet-container{
    /* cursor: pointer !important; */
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    display: none !important;
    visibility: hidden !important;
}
		
</style>