<template>
    <ion-header>
      <ion-toolbar v-if="showLogo">
        <img id="menu-logo" slot="start" src="/tripymap_the_route.svg" alt=""/>

        <ion-segment slot="start" @ionChange="" style="max-width:600px" mode="md">
          <ion-segment-button v-for="(p) in appPages" layout="icon-start" :router-link="p.url" >
            <ion-icon aria-hidden="true"  :icon="p.icon" color="tertiary"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <ion-buttons slot="start">
          <ion-button @:click="newTripModal.open()" fill="solid" color="primary" size="medium">
            <ion-row>
              <ion-col size="12" class="ion-no-padding">
                <MaterialSymbol name="add_location_alt" class="tab-icon" size="2rem"></MaterialSymbol>
              </ion-col>
              <ion-col size="12" class="ion-no-padding">
                <ion-text>{{$t('Create a trip')}}</ion-text>
              </ion-col>
            </ion-row>
          </ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button id="context-menu-trigger" class="account-button">
            {{ user && user.username ? user.username : ''}}
            <ion-icon slot="end" :icon="personCircleOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-popover trigger="context-menu-trigger" trigger-action="click" :dismissOnSelect="true">
          <div v-if="authStore.isAuthentified">
            <ion-item lines="none" button router-link="/profile/devices">
              <MaterialSymbol class="ion-margin-end" name="smart_screen"></MaterialSymbol>
              <ion-card-title>{{$t('Devices')}}</ion-card-title>
            </ion-item>
            <ion-item lines="none" button router-link="/profile/parameters">
              <ion-icon class="ion-margin-end" :icon="cogSharp"></ion-icon>
              <ion-card-title>{{$t('Settings')}}</ion-card-title>
            </ion-item>
            <ion-item lines="none" button router-link="/profile/account">
              <ion-icon class="ion-margin-end" :icon="person"></ion-icon>
              <ion-card-title>{{$t('Account')}}</ion-card-title>
            </ion-item>
            <ion-item button @click="authStore.logout()" color="primary">
                  <ion-icon :icon="powerOutline"></ion-icon>
                  <ion-label>{{$t('Logout')}}</ion-label>
            </ion-item>
          </div>
          <div v-else>
            <ion-item button color="primary" router-link="/login">
                  <ion-icon :icon="logInOutline" class="ion-margin-end"></ion-icon>
                  <ion-label>{{$t('Login')}}</ion-label>
            </ion-item>
          </div>
      </ion-popover>

    </ion-header>

    <TripModal ref="newTripModal"></TripModal>
</template>

<script setup lang="ts">
    import { personCircleOutline, navigateCircleOutline, gridOutline, peopleCircleOutline, powerOutline, cogSharp, person, logIn, logInOutline } from 'ionicons/icons';
    import TripModal from './TripModal.vue';
    import { ref, onMounted } from 'vue';
    import { useI18n } from "vue-i18n";
    import { useRouter } from 'vue-router';
    import { userAPI } from '@/libs/backend';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { AuthStore } from '@/stores/AuthStore';

    const i18n = useI18n();
    const userLoading = ref(false);
    const user:any = ref(undefined);
    const newTripModal = ref()
    const authStore = AuthStore();

    defineProps({
        showLogo: {
            type: Boolean,
            default: true
        },
    });

    const appPages = [
      {
          title: i18n.t('Dashboard'),
          url: '/dashboard',
          icon: gridOutline,
          hideMobile: true
      },
      {
          title: i18n.t('Community'),
          url: '/community',
          icon: peopleCircleOutline
      },
      {
          title: i18n.t('RoadBook'),
          url: '/roadbooks',
          icon: navigateCircleOutline
      },
    ];
    
    onMounted(()=>{
        fetchAccount()
    });

    async function fetchAccount(){
        userLoading.value = true;
        user.value = await userAPI.userInfos()
        userLoading.value = false;
    }

</script>

<style scoped>
  #menu-logo {
    width: 300px;
    margin-right: 50px;
  }
  .account-button ion-icon {
    font-size: 2.5rem !important;
  }
  .account-button {
    text-transform: unset !important;
  }
</style>