<template>
    <ion-item v-if="isDesktop" color="none" lines="none">
        
        <RoadbooksCatergoryTitle class="ion-margin-start "></RoadbooksCatergoryTitle>

        <ion-buttons v-if="roadbooksStore.galleryMode && roadbooksStore.selectionEnabled" slot="end">
            <ion-button @click="roadbooksStore.selectionEnabled = false">
                <ion-icon slot="icon-only" :icon="close" color="danger"></ion-icon>
            </ion-button>
            <ion-button id="bulk-action" :disabled="!roadbooksStore.selectedRoadbooks.length" @click="multiSelectActions.open()" color="primary">
                <ion-text class="ion-margin-end">{{roadbooksStore.selectedRoadbooks.length}}</ion-text>
                {{$t('selected')}}
                <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
            <RoadbooksMultiSelectActions ref="multiSelectActions" triggerId="bulk-action"></RoadbooksMultiSelectActions>
        </ion-buttons>

        <div v-if="isDesktop && roadbooksStore.galleryMode" v-show="!roadbooksStore.selectionEnabled" slot="end">
            <RoadbookSorter btn-class="ion-margin-end"></RoadbookSorter>
        </div>
        <ion-button v-if="isDesktop && roadbooksStore.galleryMode" v-show="!roadbooksStore.selectionEnabled" slot="end" size="medium" @click="roadbooksStore.enableSelection">
            {{$t('Select')}}
        </ion-button>
    </ion-item>
</template>
<script setup lang="ts">
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { chevronForwardOutline, close } from 'ionicons/icons';
    import { inject, ref } from 'vue';
    import RoadbooksMultiSelectActions from './RoadbooksMultiSelectActions.vue';
    import RoadbooksCatergoryTitle from './RoadbooksCatergoryTitle.vue';
    import RoadbookSorter from './RoadbookSorter.vue';

    const isDesktop = inject('isDesktop')
    const roadbooksStore = RoadbooksStore()

    // HTML Element refs
    const multiSelectActions = ref();
</script>
<style scoped>
ion-item{
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 30px;
}
</style>