<template>
    <ion-modal ref="roadbookInfoModal" v-on:keyup.escape="dismiss" :can-dismiss="true">
        <ion-content>
            <ion-row v-if="roadbookStore.id">
                <ion-col size="12">
                        <ion-item>
                            <ion-icon :icon="navigateCircleOutline" color="light" class="ion-margin-end"></ion-icon>
                            <InlineDocumentFieldEdit :autoUpdate="!roadbookStore.isNew()"  @onUpdated="roadbookStore.value.title=$event"
                                :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                                fieldName="title" placeholder="Add a title here">
                            </InlineDocumentFieldEdit>
                        </ion-item>
                        <ion-item>
                            <ion-icon :icon="menuOutline" color="light"></ion-icon>
                            <ion-title>{{ $t('Description') }}</ion-title>
                        </ion-item>

                        <ion-item>
                            <ion-icon :icon="informationCircle" color="light"></ion-icon>
                            <ion-title>{{ $t('Details') }}</ion-title>
                        </ion-item>
                        <ion-item>
                            <ion-button @click="toggleFavorite" :disabled="!roadbook" slot="end" :fill="roadbook.favorite?'outline':'clear'" color="danger" shape="round">
                                <ion-icon v-if="roadbook.favorite" slot="icon-only" :icon="heart" color="danger" class="ion-margin-end ion-padding-start"></ion-icon>
                                <ion-icon v-else slot="icon-only" :icon="heartOutline" size="large" class="ion-margin-end"></ion-icon>
                                <ion-text color="light" class="ion-padding-end">{{ $t('Favorite') }}</ion-text>
                            </ion-button>
                        </ion-item>
                        <ion-item>
                            <ion-row>
                                <ion-col size="4">{{$t('total_distance')}}</ion-col>
                                <ion-col size="8"> {{roadbook.total_distance}}</ion-col>
                                
                                <ion-col size="4">{{$t('estimated_time')}}</ion-col>
                                <ion-col size="8"> {{roadbook.estimated_time}}</ion-col>
                                
                                <ion-col size="4">{{$t('created_date')}}</ion-col>
                                <ion-col size="8"> {{roadbook.created_date}}</ion-col>
                                
                                <ion-col size="4">{{$t('updated_date')}}</ion-col>
                                <ion-col size="8"> {{roadbook.updated_date}}</ion-col>
                                
                                <ion-col size="4">{{$t('roundtrip')}}</ion-col>
                                <ion-col size="8"> {{roadbook.roundtrip}}</ion-col>
                                
                                <ion-col size="4">{{$t('hightway')}}</ion-col>
                                <ion-col size="8"> {{roadbook.hightway}}</ion-col>

                                <ion-col size="4">{{$t('tollgate_cost')}}</ion-col>
                                <ion-col size="8"> {{roadbook.tollgate_cost}}</ion-col>

                                <ion-col size="4">{{$t('Favorite')}}</ion-col>
                                <ion-col size="8"> <ion-toggle :value="roadbook.favorite" @click="toggleFavorite"></ion-toggle></ion-col>

                                <ion-col size="4">{{$t('Public')}}</ion-col>
                                <ion-col size="8"> <ion-toggle ></ion-toggle></ion-col>
                                
                            </ion-row>
                        </ion-item>
                        
                        
                </ion-col>
                    <!-- <ion-col size="12">
                        <ion-card>
                            <ion-card-content class="ion-no-padding">
                                <ion-item v-if="!roadbook || !roadbook.waypoints || roadbook.waypoints.length == 0" lines="none" color="primary" class="ion-no-padding">
                                    <ion-icon slot="start" :icon="informationCircleOutline" class="ion-margin-start"></ion-icon>
                                    <ion-text >
                                        <h2>{{$t('No waypoints have been set for this Roadbook')}}</h2>
                                    </ion-text>
                                </ion-item>
                                <Map v-if="roadbook.id" ref="mapview"
                                     :interactive="false"
                                     v-model:points="roadbook.points"
                                     v-model:pointsSections="roadbook.points_sections"
                                     v-model:waypoints="roadbook.waypoints"
                                >
                                </Map>
                            </ion-card-content>
                        </ion-card>
                    </ion-col> -->
            </ion-row>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <ion-button v-on:click="dismiss()" expand="block" size="large">
                    {{ $t('Close') }}
                </ion-button>
            </ion-toolbar>
        </ion-footer>
    </ion-modal>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUpdated, inject } from 'vue'
    import { refreshData } from '@/libs/userExperience'
    import { informationCircle, informationCircleOutline, heartOutline, heart, map, close, menuOutline, navigateCircleOutline } from 'ionicons/icons';
    import { BackendAPI } from '@/libs/backend';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import Map from '@/components/Map.vue';
    import { defaultMapLayer } from '@/libs/tileProviders';
    import { roadbookEditStore} from '@/stores/RoadbookEditStore'

    // Component event emission
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'created', data:any): void
    }>();

    defineExpose({
        dismiss,
        open,
    });

    // Store / API
    const roadbookStore = roadbookEditStore();
    const roadbookAPI = new BackendAPI('/roadbook')

    // HTML Element refs
    const roadbookInfoModal = ref();

    // Variable refs
    const roadbookId = ref('')
    const roadbook:any = ref({})
    const mapview = ref()
    const isMobile = inject('isMobile')
    const isDesktop = inject('isDesktop')
    const modalHeight = ref(isDesktop? '800px': '100%')
    const modalWidth = ref(isDesktop? '800px': '100%')

    async function fetchRoadbook(){
        if(!roadbookId.value) return
        await roadbookStore.init(roadbookId.value); // to get all map features
        roadbook.value = roadbookStore.value;
    }

    onMounted(()=>{
        fetchRoadbook();
    });

    onUpdated(()=>{
        refreshData(fetchRoadbook);
    });

    async function open(id:any){
        roadbookId.value = id;
        fetchRoadbook();
        roadbookInfoModal.value.$el.present();
    }

    function dismiss(){
        roadbookInfoModal.value.$el.dismiss();
        emit('dismiss');
    }

    function toggleFavorite(){
        roadbook.value.favorite = !roadbook.value.favorite;
        let data = {favorite: roadbook.value.favorite};
        roadbookAPI.update(roadbookId.value, data).then((res)=>{
            roadbook.value.favorite = res.favorite;
        });
    }
    

</script>

<style scoped>
ion-modal {
    --height: v-bind(modalHeight);
    --max-height: v-bind(modalHeight);
    --max-height: v-bind(modalHeight);
    --width: v-bind(modalWidth);
    --max-width: v-bind(modalWidth);
    --max-width: v-bind(modalWidth);
}

ion-modal ion-content{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-left: 3px solid var(--ion-color-petrol);
    border-right: 3px solid var(--ion-color-petrol);
    border-bottom: 3px solid var(--ion-color-petrol);
}

ion-modal .modal-wrapper{
    border-radius: 30px;
}
ion-modal ion-header ion-item,
ion-modal ion-header{
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
ion-modal ion-header{
    border-left: 3px solid var(--ion-color-petrol);
    border-right: 3px solid var(--ion-color-petrol);
    border-top: 3px solid var(--ion-color-petrol);
}

</style>

