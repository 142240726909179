import { defineStore } from 'pinia'
import { roadbookAPI, albumAPI } from '@/libs/backend'
import { actionLoader, presentToast } from '@/libs/userExperience'
import { i18n } from '@/main';
import { roadbookDistanceKm } from './RoadbookEditStore';

export const SEARCH_CATEGORY = 'search';
export const ALLROADBOOKS_CATEGORY = 'allRoadbooks';
export const UPLOADHISTORY_CATEGORY = 'uploadHistory';
export const LASTACTIVITIES_CATEGORY = 'lastActivities';
export const FAVORITES_CATEGORY = 'favorites';
export const FLASH_CATEGORY = 'flash';
export const DISCOVERY_CATEGORY = 'discovery';
export const ROUTING_CATEGORY = 'routing';
export const ARCHIVED_CATEGORY = 'archived';
export const CATEGORIES = [SEARCH_CATEGORY, ALLROADBOOKS_CATEGORY, UPLOADHISTORY_CATEGORY, LASTACTIVITIES_CATEGORY, FAVORITES_CATEGORY, FLASH_CATEGORY, DISCOVERY_CATEGORY, ROUTING_CATEGORY, ARCHIVED_CATEGORY]

/* Sorage for Roabook edition on map pages */
export const RoadbooksStore = defineStore("roadbooks", {
    state: () => ({
        displayMode: 'gallery', // 'gallery' or 'map'

        roadbooks: <Array<any>>[],          // all roadbooks fetched from backend
        displayedRoadbooks: <Array<any>>[], // filtered, sorted, ...

        albums: <Array<any>>[],

        all: <Array<any>>[],                // without archived
        lastActivities: <Array<any>>[],
        uploadHistory:  <Array<any>>[],
        favorites: <Array<any>>[],
        flash: <Array<any>>[],
        discovery: <Array<any>>[],
        routing: <Array<any>>[],
        archived: <Array<any>>[],
        categories: <object>{},

        initialized: false,
        searching: false,
        searchResults: <Array<any>>[],
        selectedCategory: <any>'allRoadbooks',
        rangeValue: { lower: 0, upper: 500 },

        selectedRoadbooks: <Array<any>>[],
        selectionEnabled: false,

        orderByField: {field: 'title', order:'asc'}
    }),
    getters: {
        maxDistance(state){
            return state.all.reduce((max, roadbook) => {
                return Math.max(max, roadbookDistanceKm(roadbook.total_distance))
            }, 500)
        },
        filtersCount(state){
            let count = 0;
            if(state.rangeValue.lower != 0 || state.rangeValue.upper != state.maxDistance) count += 1
            return count;
        },
        isAllSelected(state){
            return state.selectedRoadbooks.length == state.displayedRoadbooks.length
        },
        isAlbumSelected(state){
            return (!CATEGORIES.includes(state.selectedCategory))
        },
        galleryMode(state){
            return state.displayMode == 'gallery';
        },
        mapMode(state){
            return state.displayMode == 'map';
        },
    },
    actions: {
        /* 
          [ROADBOOK DATA ACTIONS]
        */
          async init() {
            this.$reset();
            await this.fetch()
            this.initialized = true;
        },
        async fetch() {
            try {
                roadbookAPI.disableNextErrorToast()
                this.lastActivities = await roadbookAPI.all({limit:5, sorting:['-updated_date']});
                await this.fetchAlbums();
                roadbookAPI.disableNextErrorToast()
                this.roadbooks = await roadbookAPI.all();
                this.sort();
                this.rangeValue = { lower: 0, upper: this.maxDistance }
                this.updateDisplayed(this.selectedCategory)
            }
            catch (error) {
                presentToast(i18n.global.t('Roadbooks fetch error'), 'danger');
            }
        },
        async refresh(roadbook:any) {
            let loader = await actionLoader()
            roadbookAPI.get(roadbook.id).then((refreshedRoadbook)=>{
                this.refreshRoadbookData(refreshedRoadbook);
                loader.dismiss();
            });
        },
        refreshRoadbookData(newRoadbookData:any){
            for(let r of this.roadbooks){
                if(r.id == newRoadbookData.id){
                    r = newRoadbookData
                }
            }
            this.sort();
            this.updateDisplayed();
        },
        async refreshAlbumData(newAlbumData:any){
            if(this.isAlbumSelected && this.selectedCategory.id == newAlbumData.id){
                this.selectedCategory = newAlbumData
            }
            await this.fetchAlbums()
        },
        async delete(roadbook:any){
            let loader = await actionLoader()
            roadbookAPI.delete(roadbook.id).then(()=> {
                this.roadbooks = this.roadbooks.filter((r)=>r.id != roadbook.id)
                this.sort();
                this.updateDisplayed();
                loader.dismiss();
            })
        },

        async fetchAlbums(){
            this.albums = await albumAPI.all();
        },

        searchRoadbook(searchText:string){
            if(!searchText) return
            this.searching = true
            roadbookAPI.search(searchText).then((results)=>{
                this.searchResults = results;
                this.searching = false
            })
        },

        sort(){
            this.all = []
            this.favorites = []
            this.flash = []
            this.discovery = []
            this.routing = []
            this.archived = []
            for(let roadbook of this.roadbooks){
                if(roadbook.archived == true) this.archived.push(roadbook)
                else{
                    this.all.push(roadbook)
                    if(roadbook.favorite == true) this.favorites.push(roadbook)
                    if(roadbook.type == 'flash') this.flash.push(roadbook)
                    if(roadbook.type == 'discovery') this.discovery.push(roadbook)
                    if(roadbook.type == 'routing') this.routing.push(roadbook)
                }    
                for(let album of this.albums){ // add roadbook data access to albums
                    if(album.roadbook_ids.includes(roadbook.id)){
                        if(!album.roadbooks) album.roadbooks = []
                        album.roadbooks.push(roadbook)
                    }
                }
            }
        },

        orderBy(field:string, order:string='asc'){
            this.displayedRoadbooks.sort(function(first:any, second:any) {
                let tryLower = (str:string) => {
                    if(typeof(str) == "string") return str.toLowerCase();
                    return str;
                };
                if(order == 'desc' && tryLower(second[field]) > tryLower(first[field]))
                    return 1;
                else if(order == 'asc' && tryLower(second[field]) < tryLower(first[field]))
                    return 1;
                else
                    return -1
            });
            this.orderByField.field = field;
            this.orderByField.order = order;
        },

        /* 
          [UTILS ACTIONS]
        */
        updateDisplayed(category:string|any|undefined=undefined){
            this.disableSelection();
            if(category && category != 'search') this.selectedCategory = category;

            let roadbooks:any[] = this.roadbooks

            if     (category == SEARCH_CATEGORY)          { roadbooks = this.searchResults }
            else if(category == ALLROADBOOKS_CATEGORY)    { roadbooks = this.all }
            else if(category == UPLOADHISTORY_CATEGORY)   { roadbooks = this.uploadHistory }
            else if(category == LASTACTIVITIES_CATEGORY)  { roadbooks = this.lastActivities }
            else if(category == FAVORITES_CATEGORY)       { roadbooks = this.favorites }
            else if(category == FLASH_CATEGORY)           { roadbooks = this.flash }
            else if(category == DISCOVERY_CATEGORY)       { roadbooks = this.discovery }
            else if(category == ROUTING_CATEGORY)         { roadbooks = this.routing }
            else if(category == ARCHIVED_CATEGORY)        { roadbooks = this.archived }
            else if(typeof(category) == 'object'){ // album
                for(let album of this.albums){
                    if(album.id == this.selectedCategory.id)       {
                        roadbooks = roadbooks.filter((rb)=> album.roadbook_ids.includes(rb.id))
                    }
                }
            }
            
            // add distance min/max filter
            this.displayedRoadbooks = roadbooks.filter((rb)=>
                rb.polyline && rb.polyline.length && roadbookDistanceKm(rb.total_distance) <= this.rangeValue.upper && roadbookDistanceKm(rb.total_distance) >= this.rangeValue.lower 
            )

            this.orderBy(this.orderByField.field, this.orderByField.order)

            return this.displayedRoadbooks
        },
        resetFilters(){
            this.rangeValue = {lower: 0, upper: this.maxDistance};
            this.updateDisplayed();
        },

        empty(){
            return this.roadbooks.length == 0;
        },

        populated(){
            return this.roadbooks.length > 0;
        },
        selectMode(mode:string){
            this.displayMode = mode;
            this.disableSelection()
        },
        enableSelection(){
            this.selectionEnabled = true;
            this.selectedRoadbooks = [];
        },
        disableSelection(){
            this.selectionEnabled = false;
            this.selectedRoadbooks = [];
        },
        selectAll(){
            this.selectedRoadbooks = []
            for(let roadbook of this.displayedRoadbooks){
                this.selectedRoadbooks.push(roadbook.id)
            }
        },
        isSelected(roadbook:any){
            return (this.selectedRoadbooks.indexOf(roadbook.id) != -1)
        },
        select(roadbook:any){
            if(this.selectionEnabled == true){
                let index = this.selectedRoadbooks.indexOf(roadbook.id);
                if(index == -1) this.selectedRoadbooks.push(roadbook.id)
                else{
                    this.selectedRoadbooks.splice(index,1)
                }
            }
        },
        async setPublic(isPublic:boolean, roadbook:any){
            roadbook.public = isPublic;
            roadbookAPI.update(roadbook.id, roadbook).then((refreshedRoadbook)=>{
                this.refreshRoadbookData(refreshedRoadbook);
            })
        },
        async removeRoadbooksFromCurrentAlbum(roadbookIds:any[]){
            if(!this.isAlbumSelected) return
            let loader = await actionLoader()
            const album = this.selectedCategory;
            album.roadbook_ids = album.roadbook_ids.filter((id:string)=>!roadbookIds.includes(id))
            await albumAPI.update(album.id, {roadbook_ids:album.roadbook_ids})
            await this.fetchAlbums();
            loader.dismiss()
            this.disableSelection()
            this.sort();
            this.updateDisplayed(album);
        }
    },
})