<template>
    <ion-page>
        <!-- REUSABLE TEMPLATE FOR DESKTOP/MOBILE-->
        <ContentTemplate>
            <ion-content class="ion-no-padding">
                <Map ref="map"
                    v-model:points="roadbookStore.value.points"
                    v-model:pointsSections="roadbookStore.value.points_sections"
                    v-model:waypoints="roadbookStore.value.map_waypoints"
                    :waypointProfileSelection="false"
                    :autoFocusUserLocation="false"
                    :showUserLocation="false"
                    :interactive="false"
                    @moved="mapMoved = true"
                >
                </Map>
            
                <MapRouteFitButton v-if="roadbookStore.isRoutable() && mapMoved" :fitAction="map.fitPoints" @fitted="mapMoved = false" vertical="top" horizontal="end" ></MapRouteFitButton>

            </ion-content>
        </ContentTemplate>


        <!-- DESKTOP -->
        <ion-split-pane v-if="isDesktop" when="xs" content-id="main">
            <ion-menu content-id="main">
                <ion-header>
                    <ion-toolbar>
                        <BackNavButton slot="start" :iconOnly="true"></BackNavButton>
                        <ion-item v-if="roadbookType == 'flash'" lines="none" color="none">
                            <MaterialSymbol name="flash_on" color="primary" class="ion-margin-start" size="2rem"></MaterialSymbol>
                            <ion-title class="ion-text-uppercase">{{$t('Flash route')}}</ion-title>
                        </ion-item>
                        <ion-item v-if="roadbookType == 'discovery'" lines="none" color="none">
                            <MaterialSymbol name="360" color="primary" class="ion-margin-start" size="2rem"></MaterialSymbol>
                            <ion-title class="ion-text-uppercase">{{$t('Boomerang route')}}</ion-title>
                        </ion-item>
                    </ion-toolbar>
                    <ion-progress-bar v-if="mounted && routing.loading" type="indeterminate" color="primary"></ion-progress-bar>
                </ion-header>
                <ion-content class="ion-padding">
                    <PrepareFlash v-if="roadbookType == 'flash'"></PrepareFlash>
                    <PrepareDiscovery v-if="roadbookType == 'discovery'"></PrepareDiscovery>
                </ion-content>
            </ion-menu>

            <div class="ion-page" id="main">
                <ion-content class="ion-no-padding">
                    <ReuseContentTemplate></ReuseContentTemplate>
                </ion-content>
            </div>
        </ion-split-pane>
        <!-- MOBILE -->
        <div v-else style="height:100%">
            <ion-header>
                <ion-toolbar>
                    <BackNavButton slot="start" @back="validateModal && validateModal.dismiss()"></BackNavButton>
                    <ion-title class="ion-text-uppercase">{{$t('Flash')}}</ion-title>
                                
                    <ion-progress-bar v-if="mounted && routing.loading" type="indeterminate" color="primary"></ion-progress-bar>
                </ion-toolbar>
            </ion-header>
            <ReuseContentTemplate></ReuseContentTemplate>
        </div>

        <Router ref="routing" :type="roadbookType"></Router>

        <SheetModal ref="validateModal" :breakpoint="isMobile ? 0.25:0.3" :fixed="true" desktopMaxWidth="400px">
            <template v-slot:header>
                <ion-item v-if="isDesktop" color="none" lines="none">
                    <ion-buttons slot="end">
                        <ion-button @click="validateModal.dismiss()" slot="end" size="large">
                            <ion-icon slot="icon-only" :icon="close"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-item>
            </template>
                <ion-card color="none">
                    <ion-card-content class="ion-no-padding">
                        <ion-row>
                            <ion-col size="12">
                                <InlineDocumentFieldEdit :autoUpdate="false"  @onUpdated="roadbookStore.value.title=$event"
                                    :documentAPI="roadbookAPI" :document="roadbookStore.value" :documentId="roadbookStore.id" 
                                    fieldName="title" placeholder="Add a title here"></InlineDocumentFieldEdit>
                            </ion-col>
                            <ion-col size="12">
                                <MaterialSymbol name="prompt_suggestion"></MaterialSymbol>
                                <ion-label>{{ roadbookStore.distance}}{{$t('km')}}</ion-label>
                                <MaterialSymbol name="timer" class="ion-margin-start"></MaterialSymbol>
                                <ion-label>{{ roadbookStore.time}}</ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
                <ion-row class="validate-actions ion-margin-top">
                    <ion-col size="5">
                        <ion-button v-if-mobile @click="optionsModal.open()" color="light" expand="block">
                            <MaterialSymbol slot="start" name="instant_mix"></MaterialSymbol>
                            <ion-text>{{ $t('Options') }}</ion-text>
                        </ion-button>
                    </ion-col>
                    <ion-col :size="isMobile ? 7:12">
                        <ion-button @click="saveAndUpload" expand="block">
                            <MaterialSymbol slot="start" name="smart_screen"></MaterialSymbol>
                            <ion-text>{{ $t('Upload on Tripy') }}</ion-text>
                        </ion-button>
                    </ion-col>
                </ion-row>
        </SheetModal>

        <SheetModal ref="optionsModal" :breakpoint="0.75">
            <ion-row>
                <ion-col size="12" class="ion-text-center">
                    <ion-text><h1>{{$t('Route profile')}}</h1></ion-text>
                </ion-col>
                <ion-col size="12">
                    <RouteProfileSelector class="ion-margin" :validateButton="true" :profile="roadbookStore.currentProfile()" @changed="roadbookStore.setStartProfile($event); optionsModal.dismiss()"></RouteProfileSelector>
                </ion-col>
                <ion-col size="12" class="ion-text-center">
                    <ion-text><h1>{{$t('Other options')}}</h1></ion-text>
                </ion-col>
                <ion-col size="12">
                    <ion-button @click="optionsModal.open()" color="light" expand="block" fill="outline" size="large">
                            <MaterialSymbol slot="start" name="download_for_offline" class="ion-margin-end"></MaterialSymbol>
                            <ion-text>{{ $t('Download GPX') }}</ion-text>
                        </ion-button>
                </ion-col>
            </ion-row>
        </SheetModal>

        <TripyUploadModal ref="tripyUpload" @uploaded="router.back()" :saveGPX="false"></TripyUploadModal>
    </ion-page>
    
</template>

<script setup lang="ts">
    import { ref, onMounted, watch, inject } from 'vue';
    import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
    import Map from '@/components/Map.vue';
    import Router from '@/components/Router.vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import MapRouteFitButton from '@/components/MapRouteFitButton.vue';
    import TripyUploadModal from '@/components/TripyUploadModal.vue';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import RouteProfileSelector from '@/components/RouteProfileSelector.vue';
    import SheetModal from '@/components/SheetModal.vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore'
    import { roadbookAPI } from '@/libs/backend';
    import PrepareDiscovery from '@/components/PrepareDiscovery.vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import PrepareFlash from '@/components/PrepareFlash.vue';
    import { caretForward, close } from 'ionicons/icons';
    import { createReusableTemplate } from '@vueuse/core'

    // Vue
    const route = useRoute();
    const router = useRouter();

    // Data stores
    const roadbookStore = roadbookEditStore();

    // variables refs
    const roadbookType = route.params.type
    const isDesktop = inject('isDesktop')
    const isMobile = inject('isMobile')
    const mounted = ref(false)
    const mapMoved = ref(false)

    // HTML components refs
    const map = ref();
    const save = ref();
    const routing = ref();
    const tripyUpload = ref();
    const validateModal = ref();
    const optionsModal = ref();
    const [ContentTemplate, ReuseContentTemplate] = createReusableTemplate()

    onMounted(()=>{
        // NOTE : RoadbookStore routing data is populated in 'RoadbbokPrepareFlash' page elsewhere
        if(isMobile && !roadbookStore.isRoutable()) {
            router.replace(`/roadbooks/prepare/${roadbookType}`)
        }

        // TODO get favorite map layer if present in user settings

        mounted.value = true

        if(roadbookStore.isRouted) validateModal.value.open()
    });

    onBeforeRouteLeave((to, from, next) => {
        if(validateModal.value) validateModal.value.dismiss()
        if(optionsModal.value) optionsModal.value.dismiss()
        next()
    })
    
    watch(() => roadbookStore.isRouted, (isRouted)=>{
        if(isRouted) validateModal.value.open()
    })

    async function saveAndUpload(){
        // TODO !!!!
        let roadbook = await roadbookStore.save()
        await roadbookAPI.updateDisplayOptions(roadbook.id, roadbookStore.options) // save user Map options
            // roadbookStore.$reset();
            // close();
            // router.back()
        tripyUpload.value.present(roadbook.id)
    }


</script>

<style scoped>
    ion-toolbar ion-title:hover {
        cursor: pointer;
    }

    ion-button.btn-icon-xl {
        width: 70px !important;
        height: 70px !important;
        font-size: 70px !important;
    }

    ion-button.btn-icon-md {
        width: 55px !important;
        height: 55px !important;
        font-size: 55px !important;
    }

    ion-button.go-btn {
        font-size: 30px !important;
    }

    .validate-actions i {
        font-size: 2rem !important;
        margin-right: 8px;
    }

    ion-split-pane ion-menu {
        max-width: 450px;
    }
</style>

