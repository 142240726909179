<template>
        <HeaderTemplate>
            <ion-item class="ion-no-padding ion-margin-top ion-margin-bottom" color="none" lines="none">
                <RoadbooksCatergoryTitle></RoadbooksCatergoryTitle>
                <ion-buttons v-if="!roadbooksStore.selectionEnabled" slot="end">
                    <ion-button>
                        <ion-icon slot="icon-only" color="medium" :icon="chevronDownOutline"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
        </HeaderTemplate>

        <div class="ion-margin-start">
            <ion-menu-toggle v-if="!roadbooksStore.selectionEnabled">
                <ReuseHeaderTemplate></ReuseHeaderTemplate>
            </ion-menu-toggle>
            <div v-else> <!-- prevent menu open on header click when selection is enabled -->
                <ReuseHeaderTemplate></ReuseHeaderTemplate>
            </div>
        </div>
        <ion-item v-if="!roadbooksStore.selectionEnabled" color="none" lines="none">
                <ion-searchbar :placeholder="$t('Search')" mode="ios" :debounce="300" @ionInput="roadbooksStore.searchRoadbook($event.detail.value)" @ionClear="" class="ion-no-padding"></ion-searchbar>
                <ion-buttons slot="end">
                    <ion-button id="mobile-actions" @click="mobileActions.$el.present()">
                        <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
                    </ion-button>
                    <ion-popover trigger="mobile-actions" trigger-action="click" :dismiss-on-select="true">
                            <ion-content>
                                <ion-item button color="anthracite" @click="">
                                    <MaterialSymbol name="filter_list" size="2rem" class="ion-margin-end"></MaterialSymbol>
                                    {{$t('Sort by')}}
                                </ion-item>
                                <ion-item button color="anthracite" @click="roadbooksStore.enableSelection">
                                    <ion-icon slot="start" :icon="albumsOutline"></ion-icon>
                                    <ion-label color="danger">{{$t('Select')}}</ion-label>
                                </ion-item>
                            </ion-content>
                    </ion-popover>
                </ion-buttons>
        </ion-item>
        <ion-item v-if="roadbooksStore.filtersCount">
            <ion-button v-if="roadbooksStore.filtersCount" @click="roadbooksStore.resetFilters" fill="outline" expand="block" color="light" class="ion-text-center ion-margin-bottom" size="medim">
            <ion-icon slot="start" :icon="close"></ion-icon>
            {{$t('Reset filters')}}
        </ion-button>
        </ion-item>
        <ion-item v-if="roadbooksStore.selectionEnabled" color="none" lines="none">
            <ion-buttons v-if="roadbooksStore.galleryMode && roadbooksStore.selectionEnabled" slot="end">
                <ion-button @click="roadbooksStore.selectionEnabled = false">
                    <ion-icon slot="icon-only" :icon="close" color="danger"></ion-icon>
                </ion-button>
                <ion-button id="bulk-action" :disabled="!roadbooksStore.selectedRoadbooks.length" @click="multiSelectActions.open()" color="primary">
                    <ion-text class="ion-margin-end">{{roadbooksStore.selectedRoadbooks.length}}</ion-text>
                    {{$t('selected')}}
                    <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
                </ion-button>
                <RoadbooksMultiSelectActions ref="multiSelectActions" triggerId="bulk-action"></RoadbooksMultiSelectActions>
            </ion-buttons>
        </ion-item>


        <ion-modal ref="mobileActions" trigger="mobile-actions" mode="ios" :initial-breakpoint="0.5" :breakpoints="[0, 0.5]">
            <ion-content>
                <ion-item>
                    <ion-label>{{$t('Sort by')}}</ion-label>
                </ion-item>
                <RoadbookSorter btn-class="ion-margin-end" @sorted="mobileActions.$el.dismiss()"></RoadbookSorter>
                <ion-item>
                    <ion-label>{{$t('Filters')}}</ion-label>
                </ion-item>
                <RoadbooksFilters></RoadbooksFilters>
            </ion-content>
        </ion-modal>
</template>

<script setup lang="ts">
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import RoadbooksMultiSelectActions from './RoadbooksMultiSelectActions.vue';
    import RoadbooksCatergoryTitle from './RoadbooksCatergoryTitle.vue';
    import { albumsOutline, chevronDownOutline, chevronForwardOutline, close, ellipsisVertical } from 'ionicons/icons';
    import RoadbooksFilters from './RoadbooksFilters.vue';
    import { ref } from 'vue';
    import { createReusableTemplate } from '@vueuse/core';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import RoadbookSorter from './RoadbookSorter.vue';

    // Stores
    const roadbooksStore = RoadbooksStore();

    // HTML references
    const [HeaderTemplate, ReuseHeaderTemplate] = createReusableTemplate()
    const multiSelectActions = ref()
    const mobileActions = ref()
</script>

<style scoped>
    ion-menu-toggle {
        cursor: pointer;
    }
</style>