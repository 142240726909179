<template>
    <ion-button id="create-album" slot="end" fill="outline">
                    <ion-icon slot="start" :icon="add"></ion-icon>
                    {{$t('Add')}}
    </ion-button>
    <ion-alert
            trigger="create-album"
            :header="$t('New album')"
            :buttons=" [{role: 'cancel', text: $t('Cancel')}, {role:'confirm', text:$t('OK'), handler:addAlbum}]"
            :inputs="[{placeholder: $t('Album name')}]"
    >
    </ion-alert>
</template>

<script setup lang="ts">
    import { albumAPI } from '@/libs/backend';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { add } from 'ionicons/icons';

    // Store
    const roadbooksStore = RoadbooksStore();

    function addAlbum(event:any){
        albumAPI.create({label:event[0]}).then(()=>{
            roadbooksStore.fetchAlbums()
        })
    }
</script>