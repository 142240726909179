<template>
    <div>
        <div ref="mapContainer" :style="'height:'+height+'px; width:100%'">
            <!-- overlay during map loading -->
            <PageLoader :loading="!mapReady" ></PageLoader>

            <l-map ref="map" v-model:zoom="parameters.zoom" v-model:center="parameters.center" :max-zoom="parameters.maxZoom"
                :useGlobalLeaflet="parameters.useGlobalLeaflet" :options="parameters.options" @ready="initMap"
            >
        
                <l-tile-layer :url="defaultMapLayer" layer-type="base" name="TripyMap3"></l-tile-layer>           
            
                <!-- DOCUMENTATION on  
                        https://github.com/Leaflet/Leaflet.markercluster
                        https://github.com/veitbjarsch/vue-leaflet-markercluster
                    -->

                <!-- @clusterclick="mapSelectedRoadbook = {};" -->
                <l-marker-cluster-group ref="cluster" :showCoverageOnHover="false" @layeradd="fitResults" @layerremove="fitResults">
                    <div v-for="roadbook of roadbooksStore.displayedRoadbooks">
                        <l-marker v-if="mapSelectedRoadbook.id != roadbook.id"  :lat-lng="roadbookBBoxCenter(roadbook)" 
                            @mouseover="mapOverRoadbook = roadbook"  @mouseleave="mapOverRoadbook = {}" @click="selectRoadbook(roadbook)">
                            <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                                <img src="/turn.svg" style="height:46px;width:60px;"/>
                            </l-icon>
                            <l-tooltip :options="{permanent:true, interactive:true, direction:'bottom', className:'mapview-tooltip'}" @click="selectRoadbook(roadbook)">
                                <ion-chip color="dark" mode="md" outline="false">
                                    <ion-label>
                                        <p>
                                            <MaterialSymbol name="subdirectory_arrow_right" color="primary"></MaterialSymbol>
                                            <ion-label color="light">{{ roadbookDistanceKm(roadbook.total_distance) }} {{$t('km')}}</ion-label>
                                        </p>
                                        <p>
                                            <MaterialSymbol name="timer" color="primary"></MaterialSymbol>
                                            <ion-label color="light">{{ roadbookTravelTime(roadbook.estimated_time) }}</ion-label>
                                        </p>
                                    </ion-label>
                                    
                                </ion-chip>
                            </l-tooltip>
                        </l-marker>
                    </div>
                </l-marker-cluster-group>

                <l-layer-group v-if="mapSelectedRoadbook.id">
                    <l-polyline v-if="mapSelectedRoadbook.points && mapSelectedRoadbook.points.length" :lat-lngs="mapSelectedRoadbook.points" color="#3c9bab"/>
                
                    <l-marker v-if="mapSelectedRoadbook.points && mapSelectedRoadbook.points.length"  :lat-lng="[mapSelectedRoadbook.points[0][0], mapSelectedRoadbook.points[0][1]]">
                        <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                            <img src="/start.svg" style="height:46px;width:60px;"/>
                        </l-icon>
                    </l-marker>
                    <l-marker v-if="mapSelectedRoadbook.points && mapSelectedRoadbook.points.length"  :lat-lng="[mapSelectedRoadbook.points[mapSelectedRoadbook.points.length-1][0], mapSelectedRoadbook.points[mapSelectedRoadbook.points.length-1][1]]">
                        <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                            <img src="/stop.svg" style="height:46px;width:60px;"/>
                        </l-icon>
                    </l-marker>
                </l-layer-group>

                <!-- <l-layer-group v-if="mapOverRoadbook.id">
                    <l-polyline v-if="mapOverRoadbook.points && mapOverRoadbook.points.length" :lat-lngs="mapOverRoadbook.points" color="#3c9bab"/>
                
                    <l-marker v-if="mapOverRoadbook.points && mapOverRoadbook.points.length"  :lat-lng="[mapOverRoadbook.points[0][0], mapOverRoadbook.points[0][1]]">
                        <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                            <img src="/start.svg" style="height:46px;width:60px;"/>
                        </l-icon>
                    </l-marker>
                    <l-marker v-if="mapOverRoadbook.points && mapOverRoadbook.points.length"  :lat-lng="[mapOverRoadbook.points[mapOverRoadbook.points.length-1][0], mapOverRoadbook.points[mapOverRoadbook.points.length-1][1]]">
                        <l-icon :iconSize="[46, 60]" :iconAnchor="[31, 44]" :popupAnchor="[0,-40]" :options="{className:''}">
                            <img src="/stop.svg" style="height:46px;width:60px;"/>
                        </l-icon>
                    </l-marker>
                </l-layer-group> -->
                <div v-if="mapSelectedRoadbook.id" class="map-clicked-roadbook">
                    <RoadbookCard :roadbook="mapSelectedRoadbook" :closeButton="true" @close="mapSelectedRoadbook = {}"></RoadbookCard>
                </div>
                <MapRouteFitButton :fitAction="fitCluster" @fitted="" vertical="bottom" horizontal="end" ></MapRouteFitButton>
            </l-map>

        </div>

        <ion-row v-if-mobile>
            <ion-col size="12">
                <RoadbookCard v-for="roadbook of roadbooksStore.displayedRoadbooks" :roadbook="roadbook"></RoadbookCard>
            </ion-col>
        </ion-row>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, onMounted, ref } from 'vue';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';

    /* Leaflet */
    import L from 'leaflet'
    globalThis.L = L
    import 'vue-leaflet-markercluster/dist/style.css'
    import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'

    import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LLayerGroup, LIcon } from "@vue-leaflet/vue-leaflet";
    import { defaultMapLayer } from '@/libs/tileProviders'
    import RoadbookCard from './RoadbookCard.vue';
    import PageLoader from './PageLoader.vue';
    import MapRouteFitButton from './MapRouteFitButton.vue';
    import { useRouter } from 'vue-router';
    import { roadbookBBoxCenter, roadbookDistanceKm, roadbookTravelTime } from '@/stores/RoadbookEditStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { decodePolyline } from '@/libs/geoUtils';

    // Vue
    const router = useRouter()
    
    // Stores
    const roadbooksStore = RoadbooksStore()

    // HTML Element refs
    const map = ref()
    const cluster = ref()

    // Variables
    const DEFAULT_FIT_ZOOM = 12;
    const isDesktop = inject('isDesktop')
    const isMobile = inject('isMobile')
    const mapReady = ref(true)
    const height = window.innerHeight - 215
    const parameters = {
                // TODO: local position if detected, Home country if not, else Europe center
                center: [46.66181748307193, 7.799474973236367], // centered on Europe by default
                zoom: 5,
                maxZoom: 15,
                /*
                Accessing the L global variable
                You might want to access the Leaflet global variable in your Vue component. This can be useful if you want to use a Leaflet plugin that is not available as a Vue component.
                You can still import the L global variable from the leaflet package and use it in your Vue component. Here is an example :
                */
                useGlobalLeaflet: true, // needed for MarkerCluster plugin !!!
                options: {
                    zoomControl: false, 
                    zoomSnap:0.1, // Zoom precision ratio, default 1. Usefull to use fitBounds with padding precision
                    attributionControl: false // disable leaflet map attribution label
                },
    }
    const mapSelectedRoadbook:any = ref({})
    const mapOverRoadbook:any = ref({})


    function editRoadook(roadbook:any){
        router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
    }
    
    function initMap(){
        mapReady.value = true
    }

    var fitDebounce = false;
    function fitResults(event:any){
        if(fitDebounce == true) return
        fitDebounce = true;
        
        setTimeout(() => {
            let maxZoom = DEFAULT_FIT_ZOOM;
            if(mapSelectedRoadbook.value.id){
                let fitBounds:any = []
                fitBounds.push({lat:mapSelectedRoadbook.value.bbox[0][0], lng:mapSelectedRoadbook.value.bbox[0][1]})
                fitBounds.push({lat:mapSelectedRoadbook.value.bbox[1][0], lng:mapSelectedRoadbook.value.bbox[1][1]})
                map.value.leafletObject.fitBounds(fitBounds, {maxZoom:maxZoom, paddingTopLeft: [isDesktop? 200:40, isDesktop? 200:120], paddingBottomRight: [isDesktop? 200:40, isDesktop? 200:300]});
            }
            else{
                fitCluster()
            }

            fitDebounce = false;
        }, 300);
    }

    function selectRoadbook(roadbook:any){
        if(!roadbook.points){
            // roadbook.points field will be auto saved into RoadbooksStore
            roadbook.points = decodePolyline(roadbook.polyline)
        }
        mapSelectedRoadbook.value = roadbook
    }

    function fitCluster(){
        let maxZoom = DEFAULT_FIT_ZOOM;
        var bounds = cluster.value.leafletObject.getBounds();

        if (bounds.isValid()) {
            if(roadbooksStore.displayedRoadbooks.length <= 1) maxZoom = 8;
            map.value.leafletObject.fitBounds(bounds, {maxZoom:maxZoom, paddingTopLeft: [isDesktop? 80:40, isDesktop? 80:120], paddingBottomRight: [isDesktop? 80:40, isDesktop? 80:300]});
        }
    }

    async function refresh(){
        await roadbooksStore.fetch();
    }
</script>

<style scoped>
    ion-split-pane ion-header {
        padding-top: 5px !important;
    }

    .leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
        display: none !important;
        visibility: hidden !important;
    }

    .map-clicked-roadbook {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 80px;
        height:100px;width:100px;
        z-index:99999;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .mapview-tooltip ion-chip {
        background: rgba(var(--ion-color-petrol-rgb), 1);
        opacity: 1;
        --background: rgba(var(--ion-color-petrol-rgb), 1);
    }

</style>