import { defineStore } from 'pinia'
import { roadbookAPI, graphhopperAPI } from '@/libs/backend'
import { presentToast } from '@/libs/userExperience'
import { tileProviders, defaultMapLayerId } from '@/libs/tileProviders'
import { isSameWaypoints, isNotSameWaypoints } from '@/libs/geoUtils'
import router  from '@/router'
import { toRaw } from 'vue';
import { isLoggedIn } from '@/stores/AuthStore';
import { i18n } from '@/main';
import { featureCollection, center, point } from '@turf/turf';

export function roadbookTravelTime(roadbookEstimatedTime:number){
    let seconds = Number(roadbookEstimatedTime/1000);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d == 1 ? ` ${i18n.global.t('day')}, ` : ` ${i18n.global.t('days')}, `) : "";
    var hDisplay = h > 0 ? h + i18n.global.t('h') : "";
    var mDisplay = m > 0 ? m.toString().padStart(2, "0") + i18n.global.t('min') : "";
    return dDisplay + hDisplay + mDisplay ;
}

export function roadbookDistanceKm(meterDistance:number){
  return Math.round(meterDistance/1000)
}

export function roadbookBBoxCenter(roadbook:any){
    const features = featureCollection([
        point(roadbook.bbox[0]),
        point(roadbook.bbox[1]),
    ]);
    return center(features).geometry.coordinates
}

/* Sorage for Roabook edition on map pages */
export const roadbookEditStore = defineStore("roadbook", {
    state: () => ({
      id: 'new',
      /* backend data representation */
      value: {
          id: '',
          title: '',
          owner_id: undefined,
          type: '',
          favorite: false,
          total_distance: 0,
          total_waypoints: 0,
          estimated_time: 0,
          bbox: undefined,
          hightway: false,
          tollgate: false,
          tollgate_cost: 0,
          map_center: <Array<number[]>>[],
          map_zoom: false,
          roundtrip: false,
          roundtrip_heading: 0,
          roundtrip_distance: 0,
          roundtrip_seed: 0,
          points: <Array<number[]>>[],
          polyline: '',
          points_sections: <Array<Array<number|number|String>>>[],
          waypoints: <Array<Array<number|string>>>[],
          map_waypoints: <Array<Array<number|string>>>[],
          thumbnail: undefined
      },
      initValue: <any>{},
      /* Store status */
      fetching: false,
      initialized: false,
      popoverOpened: false,
      /* Graphhopper profiles */
      profiles: <any>[],
      default_profile: 'highway',
      /* Roadbook options */
      options: {
        marker_number: true,
        locked_destination: false,
        automatic_feet: false,
        map_zoom_buttons: false,
        display_poi: false,
        map_layer_id: defaultMapLayerId,
        generate_pictos: false
      },
    }),
    getters: {
      roadbook(state){
          return state.value
      },
      isRouted(state){
          return state.value.points.length
      },
      distance(state){
          return roadbookDistanceKm(state.value.total_distance)
      },
      time(state){
          return roadbookTravelTime(state.value.estimated_time)
      },
      map_layer(state){
        return tileProviders[state.options.map_layer_id].url
      }
    },
    actions: {
      /* 
        [ROADBOOK DATA ACTIONS]
      */
      async fetch(id:string) {
        try {
          roadbookAPI.disableNextErrorToast()
          let roadbook = await roadbookAPI.get(id);
          let displayOptions = await roadbookAPI.displayOptions(id);
          if(!displayOptions.map_layer_id){
            displayOptions.map_layer_id = defaultMapLayerId;
          }
          
          if(roadbook.success == false) {
            router.replace('/roadbook/oops')
            return
          }

          this.value = roadbook;
          this.options = displayOptions;
          this.id = id;
        }
        catch (error) {
          presentToast(i18n.global.t('Roadbook fetch error'), 'danger');
        }
      },
      async init(id:string='new', roadbookType:string=''){
        this.$reset();
        this.id = id;
        this.fetching = true;
        if(this.isNew()) {
          this.value.title = await this.generateTitle(roadbookType);
          this.value.type = roadbookType;
        }
        else{
          await this.fetch(this.id)
        }
        this.profiles = await graphhopperAPI.profiles();
        this.fetching = false;
        
        if(!this.value.points) this.value.points = [];
        if(!this.value.polyline) this.value.polyline = '';
        if(!this.value.waypoints) this.value.waypoints = [];
        if(!this.value.map_waypoints) this.value.map_waypoints = [];
        if(!this.options.map_layer_id) this.options.map_layer_id = defaultMapLayerId;

        this.initialized = true;
      },
      async save(){
          let data:any = {...this.value}

          if(this.isNew()){
              delete data.id
              return roadbookAPI.create(data);
          }
          else{
              let id = this.id
              return roadbookAPI.update(id, data);
          }
      },

      /* 
        [UTILS ACTIONS]
      */
      isNew(){
        return (this.id == 'new')
      },
      isRoundTrip(){
        return this.isRoutable() && this.value.roundtrip == true;
      },
      isStartWaypoint(waypoint:any){
        let wpIndex = this.waypointIndex(waypoint) 
        return (wpIndex == 0)
      },
      isDestinationWaypoint(waypoint:any){
        let wpIndex = this.waypointIndex(waypoint) 
        return (wpIndex == this.value.map_waypoints.length-1)
      },
      isWaypointProfiled(waypoint:any){
        return (waypoint && waypoint.length === 3 && waypoint[2])
      },
      isSectionDivisibleBefore(mapWaypoint:any){
        return this.getMiddleWaypointBefore(mapWaypoint) != undefined
      },
      isSectionDivisibleAfter(mapWaypoint:any){
        return this.getMiddleWaypointAfter(mapWaypoint) != undefined
      },
      isLastStep(waypoint:any){
        let index = this.waypointIndex(waypoint);
        if(this.isRoundTrip()){
          if(this.value.map_waypoints.length > 2) return (index == this.value.map_waypoints.length-2)
          else return false
        }
        else{
          return (index == this.value.map_waypoints.length-1)
        }
      },
      async generateTitle(roadbookType:string){
        let title = i18n.global.t(roadbookType)

        if(isLoggedIn()){
          title = await roadbookAPI.generateTitle(roadbookType)
        }
        return title;
      },

      /* 
        [WAYPOINT ACTIONS]
        Important : router will calculate itself new route on 'mapWaypoints' value change 
      */
      clear(){
        this.value.points = []
        this.value.polyline = ''
        this.value.points_sections = []
        this.value.waypoints = []
        this.value.map_waypoints = []
      },
      waypointIndex(waypoint:any):number{
        let i:number = -1;
        for(i of this.value.map_waypoints.keys()) {
          if(isSameWaypoints(this.value.map_waypoints[i], waypoint)) {
            return i;
          }
        };
        return -1;
      },
      addWaypoint(waypoint:any){
          if(this.isRoundTrip() || this.options.locked_destination){
            this.value.map_waypoints.splice(this.value.map_waypoints.length-1,0, waypoint)  
          }
          else{
            this.value.map_waypoints.push(waypoint)
          }
          if(this.value.map_waypoints.length == 1){ // first waypoint must have a profile
            this.setWaypointProfile(this.currentProfile(), waypoint);
          }
      },
      addStep(waypoint:any){
        if(this.isRoutable()){
          this.value.map_waypoints.splice(this.value.map_waypoints.length-1,0, waypoint)
        }
        else
          this.addWaypoint(waypoint)
      },
      insertWaypoint(waypoint:any, wpnumber:number){
          this.value.map_waypoints.splice(wpnumber,0, waypoint)
      },
      moveWaypoint(wpIndex:number, newWaypoint:any){
        // Start/Destination points are specific move on roundtrip mode
        if(this.isRoundTrip() && (wpIndex == 0 || wpIndex == this.value.map_waypoints.length-1)){
          newWaypoint[2] = this.value.map_waypoints[0][2] // set profile
          this.value.map_waypoints[0] = newWaypoint;
          newWaypoint[2] = this.value.map_waypoints[this.value.map_waypoints.length-1][2] // set profile
          this.value.map_waypoints[this.value.map_waypoints.length-1] = newWaypoint;
        }
        else{
          if(this.isWaypointProfiled(this.value.map_waypoints[wpIndex])) newWaypoint[2] = this.value.map_waypoints[wpIndex][2]
          this.value.map_waypoints[wpIndex] = newWaypoint;
        }
      },
      removeWaypoint(waypoint:any){
        let delProfile = waypoint[2]
        let wasStart = this.isStartWaypoint(waypoint)             // check for roundtrip
        let wasDestination = this.isDestinationWaypoint(waypoint) // check for roundtrip
        
        // will delete both start/destination on roundtrip
        this.value.map_waypoints = this.value.map_waypoints.filter((el:any) => 
          isNotSameWaypoints(el, waypoint)
        )
        if(wasStart && this.value.map_waypoints.length && ! this.isWaypointProfiled(this.value.map_waypoints[0])) this.setWaypointProfile(delProfile, this.value.map_waypoints[0])
        if(this.value.map_waypoints.length < 2) this.value.roundtrip = false;
        if(this.isRoundTrip() && (wasStart || wasDestination)) this.value.map_waypoints.push(this.value.map_waypoints[0]) // recreate roundtrip
      },
      setStart(waypoint:any){
        if(!this.value.map_waypoints.length){ // first point
          this.addWaypoint(waypoint)
        }
        else {
            this.moveWaypoint(0, waypoint)
        }
      },
      setDestination(waypoint:any){
        if(this.isRoundTrip() || this.value.map_waypoints.length < 1) return // do not set destination on roundtrip
        if(this.value.map_waypoints.length == 1){ // first destination
          this.addWaypoint(waypoint)
        }
        else{
          this.moveWaypoint(this.value.map_waypoints.length-1, waypoint)
        }
      },
      currentProfile():any{
        if(this.value.map_waypoints.length){
          // roundtrip at least on profile is set
          if(this.isRoundTrip() && this.value.map_waypoints.length > 2 && this.getWaypointProfile(this.value.map_waypoints[this.value.map_waypoints.length-2])) 
            return this.getWaypointProfile(this.value.map_waypoints[this.value.map_waypoints.length-2])
          // at least on profile is set
          if (this.getWaypointProfile(this.value.map_waypoints[this.value.map_waypoints.length-1]))
            return this.getWaypointProfile(this.value.map_waypoints[this.value.map_waypoints.length-1])
        }
        return this.default_profile;
      },
      setStartProfile(profile_id:string){
        if(!this.value.map_waypoints.length) {                                 // can not add profile on unexisting waypoints
          this.default_profile = profile_id
          return;
        }
        else{
          this.value.map_waypoints[0][2] = profile_id
        }
      },
      setCurrentProfile(profile_id:string){
        if(!this.value.map_waypoints.length) {                                 // can not add profile on unexisting waypoints
          this.default_profile = profile_id
          return;
        }
        if(this.isRoundTrip() && this.value.map_waypoints.length > 2){         // update last waypoint only
          this.value.map_waypoints[this.value.map_waypoints.length-2][2] = profile_id
        }
        else{
          this.value.map_waypoints[this.value.map_waypoints.length-1][2] = profile_id
        }
      },
      setWaypointProfile(profile_id:string, waypoint:any):any{
        let index  = this.waypointIndex(waypoint)
        this.value.map_waypoints[index][2] = profile_id
      },
      getWaypointProfile(waypoint:any){
        let wpIndex:number = this.waypointIndex(waypoint)
        if(!this.isWaypointProfiled(this.value.map_waypoints[wpIndex])){ // retrieve last waypoint profile
          for(let i=wpIndex ; i >= 0 ; i--){
            if(this.isWaypointProfiled(this.value.map_waypoints[i])) return this.value.map_waypoints[i][2]
          }
        }
        else return this.value.map_waypoints[wpIndex][2]
      },
      switchWaypoints(wp1:any, wp2:any){
        if(this.isRoutable()){
          let indexWP1 = this.waypointIndex(wp1);
          let indexWP2 = this.waypointIndex(wp2);
          let tpmWP1 = this.value.map_waypoints[indexWP1]
          let tpmWP2 = this.value.map_waypoints[indexWP2]
          
          this.value.map_waypoints[indexWP1] = tpmWP2;
          this.value.map_waypoints[indexWP2] = tpmWP1;

          // Do not switch profiles
          if(this.isWaypointProfiled(tpmWP1)) this.value.map_waypoints[indexWP1][2] = tpmWP1[2]
          if(this.isWaypointProfiled(tpmWP2)) this.value.map_waypoints[indexWP2][2] = tpmWP2[2]
        }
      },
      switchStartDest(){
        if(this.isRoutable()){
          // save profile orders
          let start_profile:any = this.value.map_waypoints[0][2];
          // always remove destination profile
          if(this.isWaypointProfiled(this.value.map_waypoints[this.value.map_waypoints.length-1])){
            this.value.map_waypoints[this.value.map_waypoints.length-1].pop()
          }
          let profiles:any = []
          for(let wp of this.value.map_waypoints.values()){
            if(this.isWaypointProfiled(wp)) profiles.push(wp[2])
          }

          // reverse map waypoints
          this.value.map_waypoints = toRaw(this.value.map_waypoints).toReversed();

          // restore profile orders
          this.value.map_waypoints[0][2] = start_profile; // always restore start profile
          for(let i of this.value.map_waypoints.keys()){
            if(this.isWaypointProfiled(this.value.map_waypoints[i])){
              this.value.map_waypoints[i].pop() // remove profile
              this.value.map_waypoints[i][2] = profiles.pop() // set reverse profile
            }
          }
        }
      },
      switchRoundTrip(){
        if(!this.isRoundTrip()){
            this.addWaypoint(this.value.map_waypoints[0])
        }
        else{
            this.value.map_waypoints.pop()
        }
        this.value.roundtrip = !this.value.roundtrip;
      },
      getSectionMiddleWaypoint(sectionIndex:number){
        if(sectionIndex < 0 || ! this.value.points_sections) return undefined
        let section:any = this.value.points_sections[sectionIndex]
        if(!section) return undefined
        let sectionStart = section[0]
        let sectionEnd = section[1]
        let sectionLength = sectionEnd - sectionStart
        if(sectionLength >= 3){
          return this.value.points[sectionStart+Math.floor(sectionLength/2)+1]
        }
        else if (sectionLength == 2) {
          return this.value.points[sectionStart+1]
        }
        else{
          return undefined;
        }
      },
      getMiddleWaypointBefore(mapWaypoint:any){
        let mapWaypointIndex = this.waypointIndex(mapWaypoint)
        // get section index
        let sectionIndex = mapWaypointIndex - 1;
        if(sectionIndex < 0 && this.isRoundTrip()) sectionIndex = this.value.points_sections.length - 1 
        if(sectionIndex < 0 && !this.isRoundTrip()) return
        // get waypoint from section
        return this.getSectionMiddleWaypoint(sectionIndex)
      },
      getMiddleWaypointAfter(mapWaypoint:any){
        let mapWaypointIndex = this.waypointIndex(mapWaypoint)
        // get section index
        let sectionIndex = mapWaypointIndex;
        if(sectionIndex > this.value.points_sections.length - 1 && this.isRoundTrip()) sectionIndex = 0
        if(sectionIndex > this.value.points_sections.length - 1  && !this.isRoundTrip()) return
        // get waypoint from section
        return this.getSectionMiddleWaypoint(sectionIndex)
      },
      addWaypointBefore(mapWaypoint:any){
        let mapWaypointIndex = this.waypointIndex(mapWaypoint)
        let waypoint = this.getMiddleWaypointBefore(mapWaypoint)
        if(waypoint && mapWaypointIndex != -1)
          this.insertWaypoint(waypoint, mapWaypointIndex)
      },
      addWaypointAfter(mapWaypoint:any){
        let mapWaypointIndex = this.waypointIndex(mapWaypoint)
        let waypoint = this.getMiddleWaypointAfter(mapWaypoint)
        if(waypoint && mapWaypointIndex != -1)
          this.insertWaypoint(waypoint, mapWaypointIndex+1)
      },

      
      isRoutable(){
        if(this.value.type == 'discovery' && this.value.map_waypoints.length >= 1){
          return true
        }
        else {
          return (this.value.map_waypoints.length >= 2)
        }
      },
    },
})