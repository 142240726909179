<template>
    <ion-modal ref="albumModal" mode="ios" class="roadbook-album-modal">
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ $t('Albums') }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-item v-for="album of roadbooksStore.albums" color="none" button @click="selectAlbum(album)" mode="md">
                <MaterialSymbol color="primary" name="bookmark"  class="ion-margin-end"></MaterialSymbol>
                <ion-text>{{ album.label }}</ion-text>
                <ion-checkbox slot="end" label-placement="start" :checked="isAlbumChecked(album)"></ion-checkbox>
            </ion-item>
        </ion-content>
        <ion-footer>
            <ion-row>
                <ion-col size="6">
                    <ion-button @click="albumModal.$el.dismiss()" expand="block" color="medium">
                        {{ $t('Close') }}
                    </ion-button>
                </ion-col>
                <ion-col size="6">
                    <ion-button @click="addSelectedRoadbooksToAlbum" expand="block" color="primary">
                        <ion-text>{{ $t('Add') }}</ion-text>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-footer>
    </ion-modal>

</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { RoadbooksStore } from './stores/RoadbooksStore';
    import { actionLoader } from './libs/userExperience';
    import { albumAPI } from './libs/backend';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';

    // Stores
    const roadbooksStore = RoadbooksStore()
    // HTML references
    const albumModal = ref()
    // Variables
    const selectedAlbums:any = ref([])

    defineExpose({
        dismiss,
        open,
    });

    async function open(){
        albumModal.value.$el.present();
    }

    function dismiss(){
        albumModal.value.$el.dismiss();
    }

    async function addSelectedRoadbooksToAlbum(){
        let loader = await actionLoader()
        let albums = roadbooksStore.albums.filter((album)=>selectedAlbums.value.includes(album.id))
        for(let album of albums){
            album.roadbook_ids = album.roadbook_ids.filter((id:string)=>!roadbooksStore.selectedRoadbooks.includes(id))  // uniq ID through both list
            album.roadbook_ids = [...album.roadbook_ids, ...roadbooksStore.selectedRoadbooks];
            await albumAPI.update(album.id, {roadbook_ids:album.roadbook_ids})
        }
        await roadbooksStore.fetchAlbums();
        albumModal.value.$el.dismiss()
        loader.dismiss()
        roadbooksStore.disableSelection()
    }

    function selectAlbum(album:any){
        let index = selectedAlbums.value.indexOf(album.id);
        if(index == -1) selectedAlbums.value.push(album.id)
        else{
            selectedAlbums.value.splice(index,1)
        }
    }

    function isAlbumChecked(album:any){
        return selectedAlbums.value.includes(album.id)
    }
</script>