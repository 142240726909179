<template>
    <ion-button v-if-desktop @click="save()" slot="end" class="ion-margin-end" shape="round" size="large" :disabled="!canSave">
        <ion-icon slot="start" :icon="saveOutline"></ion-icon> {{ $t('Save') }}
    </ion-button>

    <ion-buttons v-if-mobile slot="end">
        <ion-button @click="save()" slot="end" size="large" color="primary" class="btn-icon-xl" :disabled="!canSave">
            <ion-icon slot="icon-only" :icon="saveOutline"></ion-icon>
        </ion-button>
    </ion-buttons>

</template>

<script setup lang="ts">
    import { presentToast } from '@/libs/userExperience'
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { saveOutline } from 'ionicons/icons';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { roadbookAPI } from '@/libs/backend'
    import { ref } from 'vue'

    // Store
    const roadbookStore = roadbookEditStore();
    // Vue
    const i18n = useI18n();
    const router = useRouter();
    // Variables
    var saving = ref(false);

    defineProps({
        slot: {
            type: String,
            default: 'end'
        },
        canSave: {
            type: Boolean,
            default: false
        }
    });

    defineExpose({
        save,
    });

    const emit = defineEmits(['saving', 'exit'])
   

    async function save(){
        if(saving.value == true) {
            presentToast(i18n.t('Save in progress'), 'medium');
            return
        }
        saving.value = true;
        emit('saving', saving.value)

        roadbookStore.save().then(async (roadbook:any)=>{
            await roadbookAPI.updateDisplayOptions(roadbook.id, roadbookStore.options) // save user Map options
            roadbookStore.$reset();
            router.back()
        }).finally(()=>{
            saving.value = false;
            emit('saving', saving.value)
        });
    }
    
</script>

<style scoped>
</style>