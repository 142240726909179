<template>

    <ion-item :id="'roadbook-item-'+roadbook.id" button color="petrol" :detail="!isMobile" lines="none" class="roadbook-item"
        @mouseover="isHover=isDesktop" @mouseleave="isHover=false" v-on-long-press.prevent="select" @click="emit('select', $event)">
        <ion-icon v-if="selectionMode && selected" slot="start" :icon="checkboxOutline" color="tertiary"></ion-icon>
        <ion-icon v-else-if="selectionMode && !selected" slot="start" :icon="squareOutline" color="light"></ion-icon>
        <ion-card>
            <ion-card-header>
                <ion-card-title>{{ roadbook.title }}</ion-card-title>
                <ion-card-subtitle>
                    <span>
                        <MaterialSymbol color="primary" class="vcenter" name="subdirectory_arrow_right"></MaterialSymbol>
                        <ion-text color="light">{{ roadbookDistanceKm(roadbook.total_distance) }} {{$t('km')}}</ion-text>
                    </span>
                    <span class="ion-margin-start ion-margin-end">
                        <MaterialSymbol class="vcenter" name="timer" color="primary"></MaterialSymbol>
                        <ion-text color="light">{{ roadbookTravelTime(roadbook.estimated_time) }}</ion-text>
                    </span>
                    <ion-chip v-if="roadbook.favorite" mode="ios" outline="true">
                        <MaterialSymbol color="danger" name="favorite"></MaterialSymbol>
                        {{$t('favorite')}}
                    </ion-chip>
                    <ion-chip v-if="roadbook.archived" mode="ios" outline="true">
                        <MaterialSymbol color="medium" name="inventory_2"></MaterialSymbol>
                        {{$t('archived')}}
                    </ion-chip>
                    <ion-chip v-if="devicesStore.isRoadbookUploadPending(roadbook.id)" color="primary" mode="ios" outline="true">
                        <ion-icon  :icon="cloudUploadOutline"></ion-icon>
                        <ion-label>{{$t('upload')}}</ion-label>
                    </ion-chip>
                </ion-card-subtitle>
                <ion-card-subtitle>
                    {{ roadbook.description }}
                </ion-card-subtitle>
            </ion-card-header>
        </ion-card>
        <ion-buttons v-show="!selectionMode && !publicHover" slot="end" @click="$event.stopPropagation()" class="ion-margin-end">
            <ion-button v-if="isHover" color="primary" @click="emit('select', $event)" size="medium">
                <ion-icon slot="start" :icon="create"></ion-icon>
                {{ $t('Edit') }}
            </ion-button>
            <ion-button v-if="isHover" color="primary" @click="infoModal.open(roadbook.id)" size="medium">
                <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
                {{ $t('Informations') }}
            </ion-button>
        </ion-buttons>
        <Popper slot="end" hover placement="left" :arrow="true" :class="roadbook.public ? 'popper-success':'popper-info'">
            <ion-toggle slot="end" v-model="roadbook.public" mode="ios" color="success" :disabled="selectionMode"
                @mouseover="publicHover=isDesktop" @mouseleave="publicHover=false"
                @click="$event.stopPropagation()" @ionChange="roadbooksStore.setPublic($event.detail.checked, roadbook)"></ion-toggle>
            <template #content>
                <div v-if="roadbook.public"><MaterialSymbol name="share" class="vcenter"></MaterialSymbol> {{ $t('Shared to community') }}</div>
                <div v-else><MaterialSymbol name="lock" class="vcenter"></MaterialSymbol> {{$t('Private trip')}}</div>
            </template>
        </Popper>
        <ion-buttons v-show="!selectionMode" slot="end" @click="$event.stopPropagation(); openMenu()">
            <ion-button :id="'roadbook-'+roadbook.id">
                <ion-icon slot="icon-only" color="light" :icon="ellipsisVertical"></ion-icon>
            </ion-button>
            <ion-button v-if="closeButton" @click="emit('close')">
                <ion-icon slot="icon-only" color="light" :icon="close"></ion-icon>
            </ion-button>
        </ion-buttons>

        <!-- Must be inner of ion-item to prevent glitches -->
        <RoadbookInfoModal ref="infoModal"></RoadbookInfoModal>

        <MenuTemplate>
            <ion-item button :detail="isMobile" @click="rename(roadbook)" color="none" lines="none">
                <MaterialSymbol slot="start" name="edit" class="ion-margin-end"></MaterialSymbol>
                {{ $t('Rename') }}
            </ion-item>
            <ion-item button :detail="isMobile" @click="copyRoadbook(roadbook)" color="none" lines="none">
                <MaterialSymbol slot="start" name="content_copy" class="ion-margin-end"></MaterialSymbol>
                {{ $t('Copy') }}
            </ion-item>
            <ion-item button :detail="isMobile" @click="toggleFavorite(roadbook)" color="none" lines="none">
                <MaterialSymbol v-if="roadbook.favorite" name="heart_minus" class="ion-margin-end" color="medium"></MaterialSymbol>
                <MaterialSymbol v-else name="heart_plus" class="ion-margin-end"></MaterialSymbol>
                <ion-text v-if="roadbook.favorite">{{ $t('Remove from favorite') }}</ion-text>
                <ion-text v-else>{{ $t('Add to favorites') }}</ion-text>
            </ion-item>
            <ion-item button :detail="isMobile" @click="albumModal.open()" color="none" lines="none">
                <MaterialSymbol name="bookmark_add" class="ion-margin-end"></MaterialSymbol>
                {{$t('Add to album')}}
            </ion-item>
            <ion-item v-if="roadbooksStore.isAlbumSelected" button :detail="isMobile" @click="roadbooksStore.removeRoadbooksFromCurrentAlbum([roadbook])" color="none" lines="none">
                <MaterialSymbol name="bookmark_remove" class="ion-margin-end" color="medium"></MaterialSymbol>
                {{$t('Remove from album')}}
            </ion-item>
            <ion-item-divider color="medium"></ion-item-divider>
            <ion-item button :detail="isMobile" @click="uploadRoadbook(roadbook)" color="none" lines="none">
                <ion-icon slot="start" :icon="cloudUpload" color="tertiary"></ion-icon>
                {{ $t('Add to Tripy device') }}
            </ion-item>
            <ion-item-divider color="medium"></ion-item-divider>
            <ion-item button :detail="isMobile" @click="archiveRoadbook(roadbook)" color="none" lines="none">
                <ion-icon slot="start" :icon="archive"></ion-icon>
                {{ $t('Archive') }}
            </ion-item>
            <ion-item button :detail="isMobile" @click="deleteRoadbook(roadbook)" color="none" lines="none">
                <ion-icon slot="start" :icon="trashBinOutline" color="danger"></ion-icon>
                {{ $t('Delete') }}
            </ion-item>
        </MenuTemplate>

        <ion-popover v-if="isDesktop" :trigger="'roadbook-'+roadbook.id" :dismiss-on-select="true">
            <ion-content>
                <ion-list>
                    <ReuseMenuTemplate></ReuseMenuTemplate>
                </ion-list>
            </ion-content>
        </ion-popover>

        <ion-modal ref="roadbookMenuModal" v-if="isMobile" :initial-breakpoint="0.7" :breakpoints="[0, 0.7]">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{roadbook.title}}</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <div class="ion-margin-bottom"></div>
                <ReuseMenuTemplate></ReuseMenuTemplate>
            </ion-content>
        </ion-modal>

        <AlbumModal ref="albumModal"></AlbumModal>
    </ion-item>
</template>

<script setup lang="ts">
    import { inject, ref } from 'vue';
    import Popper from "vue3-popper";
    import RoadbookInfoModal from './RoadbookInfoModal.vue';
    import AlbumModal from '@/AlbumModal.vue';
    import { DevicesStore } from '@/stores/DevicesStore';
    import { roadbookDistanceKm, roadbookTravelTime } from '@/stores/RoadbookEditStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { checkboxOutline, close, cloudUploadOutline, create, ellipsisVertical, heart, informationCircle, informationCircleOutline, squareOutline } from 'ionicons/icons';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { vOnLongPress } from '@vueuse/components'
    import { confirmDeletion, presentToast } from '@/libs/userExperience';
    import { alertController } from '@ionic/vue';
    import { useI18n } from 'vue-i18n';
    import { roadbookAPI } from '@/libs/backend';
    import { archive, arrowRedoOutline, cloudUpload, copyOutline, createOutline, heartDislike, trashBinOutline } from 'ionicons/icons';
    import { createReusableTemplate } from '@vueuse/core';

    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')
    const isHover = ref(false)
    const publicHover = ref(false)
    const roadbooksStore = RoadbooksStore()
    // Store
    const devicesStore = DevicesStore()
    // HTML ref
    const infoModal = ref()
    const roadbookMenuModal = ref()
    const albumModal = ref()

    const props = defineProps({
        roadbook: {
            type: Object,
            required: true,
        },
        selectionMode: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        closeButton: {
            type: Boolean,
            default: false,
        },
    })

    const emit = defineEmits(['select', 'refresh', 'close'])

    function select(event:any){
        console.log(event)
        if(!roadbooksStore.selectionEnabled) roadbooksStore.enableSelection()
        roadbooksStore.select(props.roadbook)
    }

    function openMenu(){
        if(isMobile) roadbookMenuModal.value.$el.present()
    }
    function dismissMenu(){
        if(isMobile) roadbookMenuModal.value.$el.dismiss()
    }


    const i18n = useI18n();

    const [MenuTemplate, ReuseMenuTemplate] = createReusableTemplate()

    function uploadRoadbook(roadbook:any){
        tripyUpload.value.present(roadbook.id)
    }

    async function archiveRoadbook(roadbook:any){
        roadbook.archived = true
        await roadbookAPI.update(roadbook.id, roadbook);
        roadbooksStore.refresh(roadbook)
        presentToast(roadbook.title+' '+ i18n.t('archived'))
    }

    async function deleteRoadbook(roadbook:any){
        if(await confirmDeletion()){
            roadbooksStore.delete(roadbook).then(()=>{
                setTimeout(() => { // wait a bit for cascade backend deletion
                    roadbooksStore.fetchAlbums();
                    dismissMenu()
                }, 300);
            });
        }
    }

    async function copyRoadbook(roadbook:any){
        let data = JSON.parse(JSON.stringify(roadbook)); // copy object
        data.title = i18n.t('Copy of') + " " + data.title
        if(data.id) delete data.id
        await roadbookAPI.create(data);
        roadbooksStore.refresh(roadbook)
        dismissMenu()
    }

    async function rename(roadbook:any){
        const alert = await alertController.create({
            header: i18n.t('Edit Title'),
            buttons: [ 
                {role:"cancel", text: i18n.t('Discard')},
                {role:"confirm", text: i18n.t('OK')}
            ],
            inputs: [
                {
                    placeholder: i18n.t('Add a title here'),
                    value: roadbook.title,
                    id: 'title-rename',
                },
            ],
        });
        await alert.present();
        // focus field on alert show
        document.getElementById('title-rename')?.focus();

        const {role, data} = await alert.onDidDismiss();
        if(role == 'confirm'){ 
            // update roadbook title
            roadbook.title = data.values[0];
            roadbookAPI.update(roadbook.id, roadbook);
            dismissMenu()
        }
    }

    async function toggleFavorite(roadbook:any){
        roadbook.favorite = !roadbook.favorite;
        let data = {favorite: roadbook.favorite};
        await roadbookAPI.update(roadbook.id, data);
        roadbooksStore.refresh(roadbook)
        dismissMenu()
    }

</script>
<style scoped>
    ion-item.roadbook-item {
        --detail-icon-opacity: v-bind(isDesktop?0:0.5);
        margin-bottom: 10px;
        border-radius: 15px;
        border: 1px solid var(--ion-color-petrol);
    }
    ion-item.roadbook-item:hover {
        border: 1px solid var(--ion-color-primary);
    }

    ion-card {
        margin: 0;
        background: transparent;
        border-width: 0px;
        box-shadow: unset;
    }
    ion-card-title {
        font-size: 1rem;
    }
    ion-toggle {
        --handle-background: white url('lock.svg');
        --handle-background-checked: white url('share-green.svg')
    }
</style>
