<template>
    <ion-page id="roadbooks-content">
        <ion-header v-if="isMobile">
            <ion-toolbar>
                <ion-title><h1>{{$t('RoadBooks')}}</h1></ion-title>
                <ion-button v-if="displayMode=='gallery'" @click="displayMode='map'" slot="end" size="large" fill="outline">
                    <ion-icon slot="icon-only" :icon="map" size="large" color="light"></ion-icon>
                </ion-button>
                <ion-button v-if="displayMode=='map'" @click="displayMode='gallery'" slot="end" size="large" fill="outline">
                    <ion-icon slot="icon-only" :icon="list" size="large" color="light"></ion-icon>
                </ion-button>
            </ion-toolbar>
        </ion-header>

        <ContentTemplate>
            <div v-if="roadbooksStore.displayMode == 'gallery'">
                <RoadbooksGallery></RoadbooksGallery>
            </div>

            <div v-if="roadbooksStore.displayMode == 'map'">
                <RoadbooksMap></RoadbooksMap>
            </div>
        </ContentTemplate>
        
        <ion-content-auth-guard>
            <PageLoader :loading="loading" :spinner="true"></PageLoader>

            <div v-if="roadbooksStore.populated()" class="content">
                <!-- DESKTOP -->
                <ion-split-pane v-if="isDesktop" when="lg" content-id="main">
                    <ion-menu content-id="main" ref="menu">
                        <ion-header>
                            <ion-segment v-model="displayMode">
                                <ion-segment-button layout="icon-top" value="gallery">
                                    <ion-icon :icon="grid"></ion-icon>
                                    <ion-label>{{$t('Gallery')}}</ion-label>
                                </ion-segment-button>
                                <ion-segment-button layout="icon-top" value="map">
                                    <ion-icon :icon="map"></ion-icon>
                                    <ion-label>{{$t('Map')}}</ion-label>
                                </ion-segment-button>
                            </ion-segment>
                        </ion-header>
                        <ion-content>
                            <RoadbooksSideMenu></RoadbooksSideMenu>
                        </ion-content>
                    </ion-menu>

                    <div class="ion-page" id="main">
                        <ion-header>
                            <RoadbooksDesktopHeader></RoadbooksDesktopHeader>
                        </ion-header>
                        <ion-content>
                            <ReuseContentTemplate></ReuseContentTemplate>
                        </ion-content>
                    </div>
                </ion-split-pane>

                <!-- MOBILE -->
                <div v-else>
                    <!-- look at 'AppMobile.vue' for Mobile menu -->
                    <RoadbooksMobileHeader></RoadbooksMobileHeader>       
                    <ReuseContentTemplate></ReuseContentTemplate>
                </div>

            </div>
            <div v-else-if="!loading" class="div-center text-center">
                <div>
                    <ion-text>
                        <h4>{{ $t("Your list is empty")}}</h4>
                    </ion-text>
                </div>
                <ion-button @click="roadbookModal.open()" shape="round" size="large" class="bold">
                    {{ $t("Create a trip")}}
                </ion-button>
            </div>
            
        </ion-content-auth-guard>

        <TripModal ref="roadbookModal"></TripModal>
    </ion-page>
</template>

<script setup lang="ts">
    import { grid, list, map } from 'ionicons/icons';
    import { ref, onMounted, onUpdated, inject, computed} from 'vue';
    import { refreshData } from '@/libs/userExperience'
    import IonContentAuthGuard from '@/components/IonContentAuthGuard.vue';
    import RoadbooksGallery from '@/components/RoadbooksGallery.vue';
    import RoadbooksMap from '@/components/RoadbooksMap.vue';
    import PageLoader from '@/components/PageLoader.vue';
    import RoadbooksDesktopHeader from '@/components/RoadbooksDesktopHeader.vue';
    import RoadbooksSideMenu from '@/components/RoadbooksSideMenu.vue';
    import { useRouter } from 'vue-router';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import TripModal from '@/components/TripModal.vue';
    import { AuthStore } from '@/stores/AuthStore';
    import { createReusableTemplate } from '@vueuse/core';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import RoadbooksMobileHeader from '@/components/RoadbooksMobileHeader.vue';

    // Variables
    const loading = ref(false)
    const isMobile = inject('isMobile')
    const isDesktop = inject('isDesktop')
    const displayMode = computed({
        get() {
            return roadbooksStore.displayMode
        },
        set(value) { roadbooksStore.selectMode(value) }
    })

    // Vue
    const router = useRouter();
    
    // HTML Element refs
    const [MenuTemplate, ReuseMenuTemplate] = createReusableTemplate()
    const [ContentTemplate, ReuseContentTemplate] = createReusableTemplate()
    const roadbookModal = ref()
    const menu = ref()

    // Store
    const roadbooksStore = RoadbooksStore();
    const authStore = AuthStore();


    onMounted(()=>{      
        fetchRoadbooks();
    });

    onUpdated(()=>{
        refreshData(fetchRoadbooks)
    });
  
    async function fetchRoadbooks(){
        if(!authStore.isLoggedIn) return
        loading.value = true;
        await roadbooksStore.init();
        loading.value = false;
    }

</script>

<style scoped>
    ion-segment {
        height: 80px;
    }

    ion-split-pane {
        background-color: var(--ion-color-petrol);
    }

    ion-split-pane ion-header {
        height: 80px;
    }

    ion-split-pane ion-menu {
        max-width: 450px;
        border-radius: 15px;
        margin-right: 30px;
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    ion-split-pane div.ion-page{
        background-color: var(--ion-color-anthracite);
        border-radius: 15px;
        margin-right: 30px;
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
</style>