<template>
    <ion-text color="light">
        <h1 class="ion-text-nowrap ion-no-margin">
        <span v-if="!roadbooksStore.selectionEnabled">
            <MaterialSymbol v-if="roadbooksStore.selectedCategory == ALLROADBOOKS_CATEGORY" color="primary" name="list" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == LASTACTIVITIES_CATEGORY" color="primary" name="history" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == UPLOADHISTORY_CATEGORY" color="primary" name="smart_screen" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == FAVORITES_CATEGORY" color="primary" name="favorite" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == FLASH_CATEGORY" color="primary" name="flash_on" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == DISCOVERY_CATEGORY" color="primary" name="360"  class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == ROUTING_CATEGORY" color="primary" name="edit_road" class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == ARCHIVED_CATEGORY" color="primary" name="inventory_2"  class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else-if="roadbooksStore.selectedCategory == SEARCH_CATEGORY" color="primary" name="search"  class="vcenter"></MaterialSymbol>
            <MaterialSymbol v-else color="primary" name="bookmark"  class="vcenter"></MaterialSymbol>
        </span>
        <span v-else>
            <!-- <RoadbooksSelectAllButton></RoadbooksSelectAllButton> -->
            <MaterialSymbol name="check_box_outline_blank" v-if="roadbooksStore.selectionEnabled && !roadbooksStore.isAllSelected" @click="roadbooksStore.selectAll" class="button vcenter"></MaterialSymbol>
            <MaterialSymbol name="check_box" v-if="roadbooksStore.selectionEnabled && roadbooksStore.isAllSelected" @click="roadbooksStore.selectedRoadbooks = []" class="button vcenter"></MaterialSymbol>
        </span>
        <span v-if="roadbooksStore.isAlbumSelected">{{ roadbooksStore.selectedCategory.label }}</span>
        <span v-else>{{ $t(roadbooksStore.selectedCategory) }}</span>
        </h1>
    </ion-text>
</template>

<script setup lang="ts">
    import { ALLROADBOOKS_CATEGORY, ARCHIVED_CATEGORY, DISCOVERY_CATEGORY, FAVORITES_CATEGORY, FLASH_CATEGORY, LASTACTIVITIES_CATEGORY, RoadbooksStore, ROUTING_CATEGORY, SEARCH_CATEGORY, UPLOADHISTORY_CATEGORY } from '@/stores/RoadbooksStore';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
import RoadbooksSelectAllButton from './RoadbooksSelectAllButton.vue';

    const roadbooksStore = RoadbooksStore()
</script>

<style scoped>
i {
  margin-right: 10px;
}
i.button{
    cursor: pointer;
}
i.button:hover {
    color: var(--ion-color-primary);
}
</style>