<template>
    <ActionContentTemplate>
        <ion-item button @click="addToAlbum" class="bold ion-margin-bottom" color="none" lines="none">
            <MaterialSymbol name="bookmark_add" class="ion-margin-end"></MaterialSymbol>
            {{$t('Add to album')}}
        </ion-item>
        <ion-item v-if="roadbooksStore.isAlbumSelected" button @click="roadbooksStore.removeRoadbooksFromCurrentAlbum(roadbooksStore.selectedRoadbooks)" class="bold ion-margin-bottom" color="none" lines="none">
            <MaterialSymbol name="bookmark_remove" class="ion-margin-end"></MaterialSymbol>
            {{$t('Remove from album')}}
        </ion-item>
        <ion-item button @click="uploadTripySelected" class="bold ion-margin-bottom" color="none" lines="none">
            <ion-icon class="ion-margin-end" :icon="cloudUpload" color="primary"></ion-icon>
            {{ $t('Add to Tripy device') }}
        </ion-item>
        <ion-item button @click="archiveSelected" class="bold ion-margin-bottom" color="none" lines="none">
            <ion-icon class="ion-margin-end" :icon="archiveOutline"></ion-icon>
            {{$t('Archive')}}
        </ion-item>
        <ion-item button @click="deleteSelected" class="bold ion-margin-bottom" color="none" lines="none">
            <ion-icon class="ion-margin-end" :icon="trashBinOutline" color="danger"></ion-icon>
            {{$t('Delete')}}
        </ion-item>
    </ActionContentTemplate>

    <ion-modal ref="multiSelectModalActions" mode="ios" class="multi-select-actions-modal" :initial-breakpoint="0.5" :breakpoints="[0, 0.5]">
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title mode="md">
                    <ion-chip color="light" outline="false">
                        <ion-label>{{roadbooksStore.selectedRoadbooks.length}}</ion-label>
                    </ion-chip>
                 {{$t('selected')}}
                 </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ReuseActionContentTemplate></ReuseActionContentTemplate>
        </ion-content>
    </ion-modal>

    <ion-popover :trigger="triggerId" :dismiss-on-select="true">
        <ion-content>
            <ion-list>
                <ReuseActionContentTemplate></ReuseActionContentTemplate>
            </ion-list>
        </ion-content>
    </ion-popover>

    <AlbumModal ref="albumModal"></AlbumModal>
</template>

<script setup lang="ts">
    import { inject, ref } from 'vue';
    import AlbumModal from '@/AlbumModal.vue';
    import { actionLoader } from '@/libs/userExperience';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { roadbookAPI } from '@/libs/backend';
    import { archiveOutline, cloudUpload, trashBinOutline } from 'ionicons/icons';
    import { createReusableTemplate } from '@vueuse/core';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';

    // Stores
    const roadbooksStore = RoadbooksStore()
    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')
    const [ActionContentTemplate, ReuseActionContentTemplate] = createReusableTemplate()
    // HTML Element refs
    const multiSelectModalActions = ref();
    const albumModal = ref()

    defineProps({
        triggerId: {
            type: String,
            required: true,
        },
    })

    defineExpose({
        open,
        dismiss,
    });

    function open(){
        if(isMobile){
            multiSelectModalActions.value.$el.present()
        }
        else{
        }
    }
    function dismiss(){
        multiSelectModalActions.value.$el.dismiss()
    }

    async function refresh(){
        await roadbooksStore.fetch();
    }

    function addToAlbum(){
        multiSelectModalActions.value.$el.dismiss();
        albumModal.value.open()
    }

    async function uploadTripySelected(){
        albumModal.value.$el.dismiss();
        multiSelectModalActions.value.$el.dismiss();
        let loader = await actionLoader();
        // TODO : accept multi ID for tripyUpload Modal
        // tripyUpload.value.present(roadbook.id)
        loader.dismiss()
    }

    async function archiveSelected(){
        multiSelectModalActions.value.$el.dismiss();
        let loader = await actionLoader()
        for(let roadbookID of roadbooksStore.selectedRoadbooks){
            await roadbookAPI.update(roadbookID, {'archived': true})
        }
        refresh();
        loader.dismiss()
    }

    async function deleteSelected(){
        multiSelectModalActions.value.$el.dismiss();
        let loader = await actionLoader()
        for(let roadbookID of roadbooksStore.selectedRoadbooks){
            await roadbookAPI.delete(roadbookID)
        }
        await refresh();
        loader.dismiss()
    }




</script>
<style scoped>
</style>