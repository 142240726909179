import jq from 'jqts';

export const maxWaypoints = 300;

export function switchLatLng(latLngList:any[]):any[]{
    /* for simple [lat,lng] */
    if(latLngList.length && typeof(latLngList[0]) == 'number'){
        return [latLngList[1], latLngList[0]]
    }

    /* for list of latlng [[lat,lng],[lat,lng],[lat,lng]] */
    let switchedList:any[] = []
    for(let el of latLngList){
        switchedList.push([el[1], el[0]])
    }
    return switchedList;
}

export function extractLineStrings(jqCompiler:jq, routerResponse:any){
    try{
        return switchLatLng(jqCompiler.evaluate(routerResponse))
    }
    catch{
        return [];
    }
}

export function isSameWaypoints(wp1:any, wp2:any){
    return wp1.length >= 2 && wp2.length >= 2 
        && JSON.stringify([wp1[0],wp1[1]]) === JSON.stringify([wp2[0],wp2[1]])
}

export function isNotSameWaypoints(wp1:any, wp2:any){
    return wp1.length >= 2 && wp2.length >= 2 
        && JSON.stringify([wp1[0],wp1[1]]) !== JSON.stringify([wp2[0],wp2[1]])
}


/*
    From Graphhopper sources : https://github.com/graphhopper/directions-api-js-client/blob/master/src/GHUtil.js
*/
export function decodePolyline(encoded:string, is3D=false) {
    let len = encoded.length;
    let index = 0;
    let array = [];
    let lat = 0;
    let lng = 0;
    let ele = 0;

    while (index < len) {
        let b;
        let shift = 0;
        let result = 0;
        do {
            b = encoded.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);
        let deltaLat = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lat += deltaLat;

        shift = 0;
        result = 0;
        do {
            b = encoded.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);
        let deltaLon = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lng += deltaLon;

        if (is3D) {
            // elevation
            shift = 0;
            result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            let deltaEle = ((result & 1) ? ~(result >> 1) : (result >> 1));
            ele += deltaEle;
            array.push([lng * 1e-5, lat * 1e-5, ele / 100]);
        } else
            array.push([lat * 1e-5, lng * 1e-5]);
    }
    // let end = new Date().getTime();
    // console.log("decoded " + len + " coordinates in " + ((end - start) / 1000) + "s");
    return array;
};